// import { isEqual } from 'lodash';
import { isEqual, startCase } from 'lodash';
import { useState, useEffect, useRef } from 'react';
// import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  Avatar,
  Collapse,
  Flex,
  Select,
  Switch,
  Popover,
  Table,
  Drawer,
  Tooltip,
  Code,
  LoadingOverlay,
} from '@mantine/core';
import { useDisclosure, useHotkeys } from '@mantine/hooks';
import {
  IconDots,
  IconChevronDown,
  IconTestPipe,
  IconChecklist,
  IconFileInvoice,
  IconChevronRight,
  IconPlus,
} from '@tabler/icons-react';
import DocumentService from 'Api/documentService';
import ExpressionService from 'Api/expressionService';
import RuleService from 'Api/ruleService';
import TagService from 'Api/tagService';
import ExpressionInput from 'Components/expression-input/ExpressionInput';
import { RuleEvalStatus } from 'Constants/index';
import { EvaluationStaus } from 'Constants/index';
import useLoading from 'Src/hooks/useLoading';
import { EphemeralExecuteDataType } from 'Types/commonTypes';
import { DocumentDataType, DocumentSnapshotType } from 'Types/docTypes';
import { EphemeralResponseType } from 'Types/ephemeralResponseType';
import { RuleType } from 'Types/ruleTypes';
import { TagSpecType, TransformedTagSpecType } from 'Types/tagTypes';
import { getNameFromEmail } from 'Utils/helper';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'Utils/notifications';
import { transformDateString } from 'Utils/transform';
import { transformTagSpecData } from 'Utils/transform';

import NestedDropdowns from '../rules/components/NestedDropdown';
import { BrowseDocumentsDrawer } from './BrowseDocumentsDrawer';

interface Document {
  id: string;
  name: string;
  status: 'Approved' | 'Rejected' | 'Flagged' | 'Error';
}

const getStatusColor = (status: Document['status']) => {
  const colors = {
    Approved: 'green',
    Rejected: 'gray',
    Flagged: 'yellow',
    Error: 'red',
  };
  return colors[status];
};

const analyticsData = {
  executed: 156,
  failed: 23,
  ignored: 45,
};

interface RuleFormState {
  title: string;
  description: string;
  example: string;
  summary: string;
  expression: {
    script: string;
    is_valid?: boolean;
  };
  on_true_eval_status?: number;
  on_false_eval_status?: number;
  on_error_eval_status?: number;
  is_active: boolean;
  tags?: any[]; // Update with proper type
}

const initialFormState: RuleFormState = {
  title: '',
  description: '',
  example: '',
  summary: '',
  expression: {
    script: '',
    is_valid: true,
  },
  is_active: true,
};

const MIN_DESCRIPTION_LENGTH = 100;

interface ActivityLogType {
  id: number;
  action: string;
  created_at: string;
  user: {
    username: string;
    avatar?: string;
  };
  details?: string;
}

export function RuleStudio() {
  const { ruleId } = useParams();
  const navigate = useNavigate();
  const [loading, handleLoading] = useLoading(false);
  const [formData, setFormData] = useState<RuleFormState>(initialFormState);
  const [isNewRule, setIsNewRule] = useState(false);
  const [ruleInfoOpened, { toggle: toggleRuleInfo }] = useDisclosure(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [ruleGenerationResponse, setRuleGenerationResponse] =
    useState<RuleType | null>(null);
  const [ruleDetails, setRuleDetails] = useState<RuleType | null>(null);
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [documentsList, setDocumentsList] = useState<DocumentSnapshotType[]>(
    []
  );
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentSnapshotType[]
  >([]);
  const [loadingDocuments, handleLoadingDocuments] = useLoading(false);
  const [tagLabel, setTagLabel] = useState('');
  const [subTagValue, setSubTagValue] = useState<string>('');
  const [newTagPopoverOpened, handleNewTagPopover] = useDisclosure(false);
  const [tagSpecs, setTagSpecs] = useState<TransformedTagSpecType[]>([]);
  const [activityLogs, setActivityLogs] = useState<ActivityLogType[]>([]);
  const [generateLoading, handleGenerateLoading] = useLoading(false);
  const [docTestStatuses, setDocTestStatuses] = useState<{
    [key: number]: {
      rationales?: any[];
      error?: boolean;
      status: string;
    };
  }>({});
  const [documentTestResultsToView, setDocumentTestResultsToView] = useState<
    number | null
  >(null);
  const [showTestResult, setShowTestResult] = useState<boolean>(false);
  const [testingLoading, handleTestingLoading] = useDisclosure(false);
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const canTestRule =
    (ruleGenerationResponse !== null || ruleDetails !== null) &&
    selectedDocuments.length > 0;

  useEffect(() => {
    if (ruleId === 'new') {
      setIsNewRule(true);
      setFormData(initialFormState);
      setActivityLogs([]);
    } else if (ruleId) {
      fetchRuleDetails(parseInt(ruleId));
    }
    fetchDocuments();
    fetchTagSpecs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleId]);

  const fetchRuleDetails = async (id: number) => {
    handleLoading.start();
    try {
      const response = await RuleService.getRulesById(id);
      if (response?.data) {
        setRuleDetails(response.data);
        setFormData({
          title: response.data.title,
          description: response.data.description || '',
          example: response.data.example || '',
          summary: response.data.summary || '',
          expression: {
            script: response.data.expression.script || '',
            is_valid: response.data.expression?.is_valid || true,
          },
          on_true_eval_status: response.data.on_true_eval_status,
          on_false_eval_status: response.data.on_false_eval_status,
          on_error_eval_status: response.data.on_error_eval_status,
          is_active: response.data.is_active,
          tags: response.data.tags,
        });
      } else {
        showErrorNotification('Error while fetching rule details');
      }
    } catch (err: any) {
      showErrorNotification(err.message ?? 'Error while fetching rule details');
    } finally {
      handleLoading.stop();
    }
  };

  const fetchDocuments = async () => {
    handleLoadingDocuments.start();
    try {
      const { data } = await DocumentService.getDocuments();
      const favoriteSnapshots = data.results.map(
        (doc: DocumentDataType) => doc.favorite_snapshot
      );
      setDocumentsList(favoriteSnapshots);
    } catch (err: any) {
      showErrorNotification(err.message ?? 'Error while fetching documents');
    } finally {
      handleLoadingDocuments.stop();
    }
  };

  const fetchTagSpecs = async () => {
    try {
      const { data } = await TagService.getTagSpecs();
      if (data && data.results) {
        const transformedData = transformTagSpecData(data.results);
        setTagSpecs(transformedData);
      } else {
        throw new Error('Error while fetching tag specs');
      }
    } catch (_) {
      showErrorNotification('Error while fetching rule tags');
    }
  };

  useEffect(() => {
    if (ruleGenerationResponse) {
      console.log('ruleGenerationResponse', ruleGenerationResponse);
    }
  }, [ruleGenerationResponse]);

  const handleGenerate = async () => {
    handleGenerateLoading.start();
    try {
      const { data } = await RuleService.generateRule({
        description: formData.description,
        example: formData.example,
        title: ruleDetails?.title || '',
      });

      // Update form data with generated rule
      setFormData((prev) => ({
        ...prev,
        title: data.title,
        summary: data.summary,
        expression: {
          script: data.expression || '',
          is_valid: true,
        },
      }));

      setRuleGenerationResponse(data);
      showSuccessNotification('Rule generated successfully');
    } catch (err: any) {
      console.log('err', err);
      showErrorNotification(err.message ?? 'Error while generating rule');
    } finally {
      handleGenerateLoading.stop();
    }
  };

  // Handle Cmd/Ctrl + F
  useHotkeys([
    [
      'mod+F',
      (e) => {
        e.preventDefault();
        searchInputRef.current?.focus();
      },
    ],
  ]);

  const descriptionLength = formData.description?.length;
  const isDescriptionValid = descriptionLength >= MIN_DESCRIPTION_LENGTH;

  const handleSaveRule = async () => {
    try {
      if (ruleDetails?.id) {
        // Update existing rule
        await RuleService.updateRules(ruleDetails.id, {
          title: formData.title,
          description: formData.description,
          expression: {
            script: formData.expression.script,
            is_valid: true,
          },
          is_active: formData.is_active,
          summary: formData.summary,
          on_true_eval_status: formData.on_true_eval_status,
          on_false_eval_status: formData.on_false_eval_status,
          on_error_eval_status: formData.on_error_eval_status,
        });

        // Handle tag updates
        await updateTagChanges();

        showSuccessNotification('Rule updated successfully');
      } else {
        // Create new rule
        const response = await RuleService.createRules({
          title: formData.title,
          description: formData.description,
          expression: {
            script: formData.expression.script,
            is_valid: true,
          },
          is_active: formData.is_active,
          summary: formData.summary,
          on_true_eval_status: formData.on_true_eval_status,
          on_false_eval_status: formData.on_false_eval_status,
          on_error_eval_status: formData.on_error_eval_status,
          tags: formData.tags,
        });

        if (response?.data?.id) {
          // Silently update URL
          navigate(`/rule-studio/${response.data.id}`, { replace: true });
          showSuccessNotification('Rule created successfully');
          setIsNewRule(false);
        }
      }

      // Refresh activity logs after save/update
      // await fetchActivityLogs();
    } catch (error: any) {
      showErrorNotification(error.message || 'Failed to save rule');
    }
  };

  const updateTagChanges = async () => {
    if (!ruleDetails?.id) return; // since BE handle tags on creation
    if (!isEqual(formData.tags, ruleDetails.tags)) {
      // find out which tags are newly added and which are removed
      const newTags = formData.tags?.filter((tag) => !tag.id);
      const removedTags = ruleDetails.tags?.filter(
        (tag) => !formData.tags?.some((t) => t.id === tag.id)
      );

      try {
        if (newTags && newTags.length > 0) {
          await Promise.all(
            newTags.map((newTag) =>
              TagService.createTags({
                ...newTag,
                entity_id: ruleDetails.id,
                entity_type: 'rules.rule',
              })
            )
          );
        }

        if (removedTags && removedTags.length > 0) {
          await Promise.all(
            removedTags.map((removedTag) =>
              TagService.deleteTags(removedTag.id)
            )
          );
        }
      } catch (error) {
        throw new Error('Failed to update tags');
      }
    }
  };

  const createNewTag = async () => {
    try {
      const tagSpec: Partial<TagSpecType> = {
        label: tagLabel,
        data_type: 'string',
        constraints: {
          allowed_values: [{ value: subTagValue, is_active: true }],
        },
      };
      await TagService.createTagSpecs(tagSpec);
      handleNewTagPopover.close();
      showSuccessNotification('Tag created successfully');
      fetchTagSpecs();
      setTagLabel('');
      setSubTagValue('');
    } catch (e: any) {
      showErrorNotification(e?.message || 'Error while saving tag');
    }
  };

  // Transform rule details into activity logs
  const getActivityLogsFromRuleDetails = (): ActivityLogType[] => {
    if (!ruleDetails) return [];

    const logs: ActivityLogType[] = [];

    // Add creation log
    if (ruleDetails.created_by && ruleDetails.created_at) {
      logs.push({
        id: 1,
        action: 'created the rule',
        created_at: ruleDetails.created_at,
        user: {
          username: ruleDetails.created_by.username,
          avatar: ruleDetails.created_by.username[0]?.toUpperCase(),
        },
      });
    }

    // Add update log if different from creation
    if (
      ruleDetails.updated_by &&
      ruleDetails.updated_at &&
      ruleDetails.updated_by.username !== ruleDetails.created_by?.username
    ) {
      logs.push({
        id: 2,
        action: 'updated the rule',
        created_at: ruleDetails.updated_at,
        user: {
          username: ruleDetails.updated_by.username,
          avatar: ruleDetails.updated_by.username[0]?.toUpperCase(),
        },
      });
    }

    // Add test log if rule was tested
    // if (ruleDetails.last_tested_at) {
    //   logs.push({
    //     id: 3,
    //     action: 'tested the rule',
    //     created_at: ruleDetails.last_tested_at,
    //     user: {
    //       username: ruleDetails.last_tested_by?.username || 'System',
    //       avatar: ruleDetails.last_tested_by?.username[0]?.toUpperCase() || 'S',
    //     },
    //     details:
    //       selectedDocuments.length > 0
    //         ? `on ${selectedDocuments.length} documents`
    //         : undefined,
    //   });
    // }

    return logs.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  };

  // Update useEffect to set activity logs when rule details change
  useEffect(() => {
    if (ruleDetails) {
      const activityLogsData = getActivityLogsFromRuleDetails();
      setActivityLogs(activityLogsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleDetails, selectedDocuments.length]);

  const extractExplanationInfo = (result: EphemeralResponseType): any[] => {
    const extractedExplanations: any[] = [];
    const execBody = result?.output_context?.exec_tree?.body;
    if (
      execBody?.exec_explanation_ctx &&
      execBody.exec_explanation_ctx.is_llm_generated
    ) {
      execBody.exec_explanation_ctx.all_responses.forEach((response) => {
        extractedExplanations.push(response);
      });
    }
    return extractedExplanations;
  };

  const testAttachedDocuments = async (
    specificDocument?: DocumentSnapshotType
  ) => {
    // run for specific documents or run for all the documents
    let runTestForSpecificDocument = [];
    if (specificDocument) {
      runTestForSpecificDocument = [specificDocument];
    } else {
      runTestForSpecificDocument = selectedDocuments;
    }
    handleTestingLoading.open();
    const promises = runTestForSpecificDocument.map((doc) => {
      const context = {
        document_snapshot_id: doc.id,
      };
      const ephemeralExecuteData: EphemeralExecuteDataType = {
        script: formData.expression.script || '',
        context,
        allowed_names: ['content', 'document', 'document_plaintext'],
      };

      return ExpressionService.ephemeralExecute(ephemeralExecuteData)
        .then(({ data }) => {
          const rationales = extractExplanationInfo(data);
          setDocTestStatuses((prevStatuses) => ({
            ...prevStatuses,
            [doc.id]: {
              rationales,
              error: false,
              status: EvaluationStaus.COMPLETED,
            },
          }));
          return data;
        })
        .catch((error) => {
          setDocTestStatuses((prevStatuses) => ({
            ...prevStatuses,
            [doc.id]: {
              error: true,
              status: EvaluationStaus.FAILED,
              rationales: [
                {
                  error: error.message || 'Test execution failed',
                },
              ],
            },
          }));
          throw error;
        });
    });

    try {
      const results = await Promise.allSettled(promises);
      console.log('results', results);
      showSuccessNotification('Test execution completed');
    } catch (e) {
      showErrorNotification('Some tests failed to execute');
    } finally {
      handleTestingLoading.close();
    }
  };

  const toggleSection = (key: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const translateObjectToReadableFormat = (data: any, depth = 0) => {
    const renderObject = (obj: any, parentKey = '') => {
      return Object.keys(obj).map((key) => {
        const value = obj[key];
        const fullKey = parentKey ? `${parentKey}.${key}` : key;

        if (Array.isArray(value)) {
          return (
            <Paper
              withBorder
              p="md"
              radius="md"
              mb="md"
              key={fullKey}
              style={{ marginLeft: depth * 16 }}
            >
              <Group
                justify="space-between"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleSection(fullKey)}
              >
                <Group>
                  <IconChevronRight
                    size={16}
                    style={{
                      transform: expandedSections[fullKey]
                        ? 'rotate(90deg)'
                        : 'none',
                      transition: 'transform 200ms ease',
                    }}
                  />
                  <Text fw={500} c="dimmed" size="sm">
                    {startCase(key)}
                  </Text>
                  <Badge size="sm" variant="dot">
                    {value.length} items
                  </Badge>
                </Group>
              </Group>

              <Collapse in={expandedSections[fullKey]}>
                <Box pl="md" mt="md">
                  {value.map((item, index) => (
                    <Paper
                      key={index}
                      withBorder
                      p="md"
                      radius="md"
                      mb="sm"
                      bg="gray.0"
                    >
                      <Group justify="space-between" mb="xs">
                        <Text size="sm" fw={500}>
                          Item {index + 1}
                        </Text>
                      </Group>
                      {typeof item === 'object' ? (
                        translateObjectToReadableFormat(item, depth + 1)
                      ) : (
                        <Code block>{JSON.stringify(item, null, 2)}</Code>
                      )}
                    </Paper>
                  ))}
                </Box>
              </Collapse>
            </Paper>
          );
        } else if (typeof value === 'object' && value !== null) {
          return (
            <Paper
              withBorder
              p="md"
              radius="md"
              mb="md"
              key={fullKey}
              style={{ marginLeft: depth * 16 }}
            >
              <Group
                justify="space-between"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleSection(fullKey)}
              >
                <Group>
                  <IconChevronRight
                    size={16}
                    style={{
                      transform: expandedSections[fullKey]
                        ? 'rotate(90deg)'
                        : 'none',
                      transition: 'transform 200ms ease',
                    }}
                  />
                  <Text fw={500} size="sm">
                    {startCase(key)}
                  </Text>
                </Group>
              </Group>

              <Collapse in={expandedSections[fullKey]}>
                <Box pl="md" mt="md">
                  {renderObject(value, fullKey)}
                </Box>
              </Collapse>
            </Paper>
          );
        } else {
          return (
            <Group key={fullKey} mb="xs" style={{ marginLeft: depth * 16 }}>
              <Text fw={500} size="sm" w={150}>
                {startCase(key)}:
              </Text>
              <Code>{String(value)}</Code>
            </Group>
          );
        }
      });
    };

    if (Array.isArray(data)) {
      return (
        <Stack>
          {data.map((item, index) => (
            <Box key={index}>
              {translateObjectToReadableFormat(item, depth + 1)}
            </Box>
          ))}
        </Stack>
      );
    }

    return <Stack>{renderObject(data)}</Stack>;
  };

  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 1 }}
      />
      <Stack gap="md" pos="relative">
        <Flex justify="space-between" align="center">
          <Box>
            <Group gap="xs">
              <Text size="sm" c="dimmed">
                Rule studio
              </Text>
              {!isNewRule && (
                <Tooltip label="New Rule">
                  <Button
                    onClick={() => {
                      navigate('/rule-studio/new');
                    }}
                    variant="transparent"
                    size="sm"
                  >
                    <IconPlus size={16} />
                  </Button>
                </Tooltip>
              )}
            </Group>
            <Title order={3}>
              {isNewRule && ruleGenerationResponse === null
                ? 'New Rule'
                : formData.title}
            </Title>
          </Box>

          {(!isNewRule || ruleGenerationResponse !== null) && (
            <Group>
              <Button
                variant="light"
                onClick={handleSaveRule}
                size="sm"
                loading={loading}
              >
                {ruleDetails?.id ? 'Update Rule' : 'Save Rule'}
              </Button>
              <Button
                size="sm"
                variant="outline"
                leftSection={<IconTestPipe size={16} />}
                disabled={!canTestRule}
                onClick={() => testAttachedDocuments()}
              >
                Run All Test
              </Button>
            </Group>
          )}
        </Flex>
        <Grid>
          <Grid.Col span={5}>
            <Stack pos="relative">
              <Paper withBorder p="md" radius="md">
                <Stack>
                  <Box>
                    <Group align="center" justify="space-between" mb="xs">
                      <Text fw={400} size="sm">
                        Rule Description
                      </Text>
                      <Text size="sm" c="dimmed">
                        min 100 characters *
                      </Text>
                    </Group>
                    <Textarea
                      minRows={4}
                      autosize
                      placeholder="Human language prompt"
                      value={formData.description}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      radius="md"
                    />
                  </Box>

                  <Box>
                    <Textarea
                      placeholder="Write an example here..."
                      value={formData.example}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          example: e.currentTarget.value,
                        }))
                      }
                      minRows={3}
                      radius="md"
                    />
                  </Box>

                  <Group>
                    <Button
                      variant="outline"
                      size="sm"
                      loading={generateLoading}
                      onClick={handleGenerate}
                      disabled={!isDescriptionValid}
                    >
                      Generate
                    </Button>
                  </Group>

                  {(!isNewRule || ruleGenerationResponse !== null) && (
                    <>
                      <Group justify="space-between" align="center">
                        <Text size="sm" c="dimmed">
                          Generated 2 days ago
                        </Text>
                        <Button
                          variant="transparent"
                          color="pink"
                          size="sm"
                          px={0}
                          onClick={toggleRuleInfo}
                          rightSection={
                            <IconChevronDown
                              size={14}
                              style={{
                                transform: ruleInfoOpened
                                  ? 'rotate(180deg)'
                                  : 'none',
                                transition: 'transform 200ms ease',
                              }}
                            />
                          }
                        >
                          Rule Info
                        </Button>
                      </Group>

                      <Collapse in={ruleInfoOpened}>
                        <Stack gap="md">
                          <Grid gutter={40}>
                            <Grid.Col span={12}>
                              <Stack>
                                <Box>
                                  <Text fw={400} size="sm" mb={4}>
                                    Title
                                  </Text>
                                  <TextInput
                                    value={formData.title}
                                    onChange={(e) =>
                                      setFormData((prev) => ({
                                        ...prev,
                                        title: e.currentTarget.value,
                                      }))
                                    }
                                    placeholder="Enter title"
                                  />
                                </Box>

                                <Box>
                                  <Text fw={400} size="sm" mb={4}>
                                    Description
                                  </Text>
                                  <Textarea
                                    value={formData.description}
                                    onChange={(e) =>
                                      setFormData((prev) => ({
                                        ...prev,
                                        description: e.currentTarget.value,
                                      }))
                                    }
                                    placeholder="This rule validates..."
                                    minRows={3}
                                    autosize
                                    maxRows={10}
                                  />
                                </Box>

                                <Group justify="space-between">
                                  <Box>
                                    <Text fw={400} size="sm" mb={4}>
                                      On True Eval Status
                                    </Text>
                                    <Select
                                      value={formData.on_true_eval_status?.toString()}
                                      onChange={(value) => {
                                        if (!value) return;
                                        setFormData((prev) => ({
                                          ...prev,
                                          on_true_eval_status: parseInt(value),
                                        }));
                                      }}
                                      data={Object.keys(RuleEvalStatus).map(
                                        (status) => {
                                          const key =
                                            status as keyof typeof RuleEvalStatus;
                                          return {
                                            value: key,
                                            label: RuleEvalStatus[key],
                                          };
                                        }
                                      )}
                                      placeholder="Select status"
                                    />
                                  </Box>

                                  <Box>
                                    <Text fw={400} size="sm" mb={4}>
                                      On False Eval Status
                                    </Text>
                                    <Select
                                      value={formData.on_false_eval_status?.toString()}
                                      onChange={(value) => {
                                        if (!value) return;
                                        setFormData((prev) => ({
                                          ...prev,
                                          on_false_eval_status: parseInt(value),
                                        }));
                                      }}
                                      data={Object.keys(RuleEvalStatus).map(
                                        (status) => {
                                          const key =
                                            status as keyof typeof RuleEvalStatus;
                                          return {
                                            value: key,
                                            label: RuleEvalStatus[key],
                                          };
                                        }
                                      )}
                                      placeholder="Select status"
                                    />
                                  </Box>
                                </Group>
                                <Box>
                                  <Text fw={400} size="sm" mb={4}>
                                    On Error Eval Status
                                  </Text>
                                  <Select
                                    value={formData.on_error_eval_status?.toString()}
                                    onChange={(value) => {
                                      if (!value) return;
                                      setFormData((prev) => ({
                                        ...prev,
                                        on_error_eval_status: parseInt(value),
                                      }));
                                    }}
                                    data={Object.keys(RuleEvalStatus).map(
                                      (status) => {
                                        const key =
                                          status as keyof typeof RuleEvalStatus;
                                        return {
                                          value: key,
                                          label: RuleEvalStatus[key],
                                        };
                                      }
                                    )}
                                    placeholder="Select status"
                                  />
                                </Box>

                                <Box>
                                  <Text fw={400} size="sm" mb={4}>
                                    Expression
                                  </Text>
                                  <ExpressionInput
                                    minRows={6}
                                    maxRows={12}
                                    autosize
                                    value={formData.expression.script}
                                    onChange={(e) =>
                                      setFormData((prev) => ({
                                        ...prev,
                                        expression: {
                                          script: e.target.value,
                                          is_valid: true,
                                        },
                                      }))
                                    }
                                    placeholder="Write your prompt expression here"
                                  />
                                </Box>

                                <Box>
                                  <Text fw={400} size="sm" mb={4}>
                                    Permissions
                                  </Text>
                                  <Select
                                    data={['Everyone']}
                                    value="Everyone"
                                    placeholder="Select permissions"
                                  />
                                </Box>

                                <Group mt="md">
                                  <Switch
                                    size="md"
                                    label="Status"
                                    offLabel="InActive"
                                    onLabel="Active"
                                    checked={formData.is_active}
                                    onChange={(event) =>
                                      setFormData((prev) => ({
                                        ...prev,
                                        is_active: event.currentTarget.checked,
                                      }))
                                    }
                                  />
                                </Group>

                                <Group
                                  mt="sm"
                                  justify="space-between"
                                  align="center"
                                >
                                  <Text fw={400} size="sm">
                                    Associate Tags
                                  </Text>
                                  <Popover
                                    width={200}
                                    trapFocus
                                    position="bottom"
                                    withArrow
                                    shadow="md"
                                    opened={newTagPopoverOpened}
                                    onClose={() => handleNewTagPopover.close()}
                                  >
                                    <Popover.Target>
                                      <Button
                                        onClick={() =>
                                          handleNewTagPopover.open()
                                        }
                                        variant="link"
                                        size="xs"
                                      >
                                        + Create New
                                      </Button>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                      <TextInput
                                        label="Tag Name"
                                        size="xs"
                                        value={tagLabel}
                                        onChange={({ target }) =>
                                          setTagLabel(target.value)
                                        }
                                      />
                                      <TextInput
                                        label="Add Sub Tag (Optional)"
                                        size="xs"
                                        value={subTagValue}
                                        onChange={({ target }) =>
                                          setSubTagValue(target.value)
                                        }
                                      />
                                      <Button
                                        onClick={() => {
                                          navigate('/tag-management');
                                        }}
                                        size="xs"
                                        mt="lg"
                                        variant="link"
                                      >
                                        Visit Tag Management
                                      </Button>
                                      <Flex mt="md" mb="md">
                                        <Button
                                          onClick={createNewTag}
                                          size="xs"
                                          variant="filled-shadow"
                                          disabled={!tagLabel}
                                        >
                                          Create
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleNewTagPopover.close()
                                          }
                                          size="xs"
                                          variant="subtle"
                                        >
                                          Cancel
                                        </Button>
                                      </Flex>
                                    </Popover.Dropdown>
                                  </Popover>
                                </Group>

                                <NestedDropdowns
                                  associatedTags={formData.tags || []}
                                  setAddFormValues={setFormData}
                                  tagSpecs={tagSpecs}
                                />
                                <Box>
                                  <Button
                                    onClick={handleSaveRule}
                                    variant="outline"
                                    size="xs"
                                  >
                                    {ruleDetails?.id
                                      ? 'Update Rule'
                                      : 'Save Rule'}
                                  </Button>
                                </Box>
                              </Stack>
                            </Grid.Col>
                          </Grid>
                        </Stack>
                      </Collapse>
                    </>
                  )}
                </Stack>
              </Paper>
              {ruleDetails !== null && (
                <Box>
                  <Box mt="xl" pos="relative">
                    <Text fw={500} mb="md">
                      Activity log
                    </Text>
                    <Paper withBorder p="md" radius="md">
                      <Stack gap="sm">
                        {activityLogs.map((log) => (
                          <Group key={log.id} wrap="nowrap" align="flex-start">
                            <Avatar
                              size="sm"
                              color={
                                log.action.includes('created')
                                  ? 'blue'
                                  : log.action.includes('updated')
                                    ? 'pink'
                                    : 'gray'
                              }
                              radius="xl"
                            >
                              {log.user.avatar}
                            </Avatar>
                            <Box style={{ flex: 1 }}>
                              <Group justify="space-between" wrap="nowrap">
                                <Text size="sm" style={{ flex: 1 }}>
                                  <Text span fw={500}>
                                    {getNameFromEmail(log.user.username)}
                                  </Text>{' '}
                                  {log.action}
                                  {log.details && (
                                    <>
                                      {' '}
                                      <Text
                                        span
                                        c="pink"
                                        style={{ cursor: 'pointer' }}
                                        fw={500}
                                      >
                                        {log.details}
                                      </Text>
                                    </>
                                  )}
                                </Text>
                                <Text size="sm" c="dimmed">
                                  {transformDateString(log.created_at)}
                                </Text>
                              </Group>
                            </Box>
                          </Group>
                        ))}
                        {activityLogs.length === 0 && (
                          <Text c="dimmed" ta="center" py="md">
                            No activity logs found
                          </Text>
                        )}
                      </Stack>
                    </Paper>
                  </Box>
                </Box>
              )}
            </Stack>
          </Grid.Col>
          {ruleGenerationResponse !== null ||
            (ruleDetails !== null && (
              <Grid.Col span={7}>
                <Paper
                  withBorder
                  radius="md"
                  p="md"
                  style={{ height: 'calc(100vh - 170px)', overflow: 'auto' }}
                >
                  <Stack h="100%" pos="relative">
                    {/* <LoadingOverlay visible={documentLoading} /> */}
                    <Group justify="space-between">
                      <Text fw={400} size="sm">
                        Select documents to test the rule
                      </Text>
                      {selectedDocuments.length > 0 && (
                        <Button
                          onClick={openDrawer}
                          variant="outline"
                          color="pink"
                          size="xs"
                        >
                          Attach Documents
                        </Button>
                      )}
                    </Group>

                    {selectedDocuments.length > 0 ? (
                      <Box
                        style={{
                          overflowY: 'auto',
                          flex: 1,
                          position: 'relative',
                          minHeight: 0,
                        }}
                      >
                        <Table verticalSpacing="xs">
                          <Table.Thead
                            style={{
                              position: 'sticky',
                              top: 0,
                              background: 'white',
                              zIndex: 2,
                            }}
                          >
                            <Table.Tr>
                              <Table.Th>
                                <Group justify="space-between">
                                  <Text fw={500} size="sm">
                                    Document name
                                  </Text>
                                </Group>
                              </Table.Th>
                              <Table.Th>
                                <Text fw={500} size="sm">
                                  Status
                                </Text>
                              </Table.Th>
                              <Table.Th style={{ textAlign: 'right' }}>
                                <Group justify="flex-end" gap="xs">
                                  <Button
                                    variant="subtle"
                                    color="gray"
                                    size="xs"
                                    onClick={() => setSelectedDocuments([])}
                                    disabled={selectedDocuments.length === 0}
                                  >
                                    Clear all
                                  </Button>
                                </Group>
                              </Table.Th>
                            </Table.Tr>
                          </Table.Thead>

                          <Table.Tbody>
                            {selectedDocuments.map((doc) => (
                              <Table.Tr key={doc.id}>
                                <Table.Td>
                                  <Group>
                                    <IconFileInvoice
                                      size={16}
                                      stroke={1.5}
                                      color="gray"
                                    />
                                    <Box style={{ flex: 1, maxWidth: 280 }}>
                                      <Tooltip
                                        label={doc.file_original_name}
                                        position="bottom-start"
                                        multiline
                                        disabled={
                                          doc.file_original_name.length <= 40
                                        }
                                      >
                                        <Text
                                          size="sm"
                                          truncate="end"
                                          style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {doc.file_original_name}
                                        </Text>
                                      </Tooltip>
                                    </Box>
                                  </Group>
                                </Table.Td>
                                <Table.Td>
                                  <Group align="center" gap="lg">
                                    {docTestStatuses[doc.id] ? (
                                      <Badge
                                        color={getStatusColor(
                                          docTestStatuses[doc.id].status as
                                            | 'Error'
                                            | 'Approved'
                                            | 'Rejected'
                                            | 'Flagged'
                                        )}
                                        variant="light"
                                      >
                                        {startCase(
                                          docTestStatuses[doc.id].status
                                        )}
                                      </Badge>
                                    ) : (
                                      <Badge color="gray" variant="light">
                                        Pending
                                      </Badge>
                                    )}
                                    {docTestStatuses[doc.id]?.status ===
                                      EvaluationStaus.COMPLETED && (
                                      <Button
                                        onClick={() => {
                                          setShowTestResult(true);
                                          setDocumentTestResultsToView(doc.id);
                                        }}
                                        size="xs"
                                        variant="subtle"
                                      >
                                        View results
                                      </Button>
                                    )}
                                  </Group>
                                </Table.Td>
                                <Table.Td style={{ textAlign: 'right' }}>
                                  <Group
                                    justify="flex-end"
                                    gap="xs"
                                    wrap="nowrap"
                                  >
                                    <Button
                                      variant="light"
                                      color="pink"
                                      size="xs"
                                      onClick={() => testAttachedDocuments(doc)}
                                      loading={testingLoading}
                                      disabled={!formData.expression.script}
                                    >
                                      Run Test
                                    </Button>

                                    <ActionIcon
                                      variant="subtle"
                                      color="gray"
                                      size="sm"
                                    >
                                      <IconDots
                                        style={{ width: '70%', height: '70%' }}
                                      />
                                    </ActionIcon>
                                  </Group>
                                </Table.Td>
                              </Table.Tr>
                            ))}

                            {selectedDocuments.length === 0 && (
                              <Table.Tr>
                                <Table.Td colSpan={3}>
                                  <Text c="dimmed" ta="center" py="md">
                                    No documents selected
                                  </Text>
                                </Table.Td>
                              </Table.Tr>
                            )}
                          </Table.Tbody>
                        </Table>
                      </Box>
                    ) : (
                      <Flex
                        align="center"
                        justify="center"
                        direction="column"
                        h="100%"
                        gap="sm"
                        bg="gray.1"
                      >
                        <IconChecklist
                          stroke={1}
                          fontWeight={100}
                          color="#868e96"
                          size={100}
                        />
                        <Text c="dimmed" size="sm">
                          Select documents to test the rule
                        </Text>
                        <Button
                          onClick={openDrawer}
                          variant="filled-shadow"
                          color="pink"
                          size="sm"
                        >
                          Browse
                        </Button>
                      </Flex>
                    )}
                  </Stack>
                </Paper>

                <Box mt="xl" pos="relative">
                  {/* <LoadingOverlay visible={loading} /> */}
                  <Text fw={400}>Analytics</Text>
                  <Collapse in={ruleInfoOpened}></Collapse>

                  <Group grow>
                    <Paper withBorder p="md" radius="md">
                      <Text ta="center" fz="xl" fw={700} c="dimmed">
                        {analyticsData.executed}
                      </Text>
                      <Text ta="center" size="sm" c="dimmed">
                        Rule executed
                      </Text>
                    </Paper>
                    <Paper withBorder p="md" radius="md">
                      <Text ta="center" fz="xl" fw={700} c="dimmed">
                        {analyticsData.failed}
                      </Text>
                      <Text ta="center" size="sm" c="dimmed">
                        Rule failed
                      </Text>
                    </Paper>
                    <Paper withBorder p="md" radius="md">
                      <Text ta="center" fz="xl" fw={700} c="gray">
                        {analyticsData.ignored}
                      </Text>
                      <Text ta="center" size="sm" c="dimmed">
                        Ignored
                      </Text>
                    </Paper>
                  </Group>
                </Box>
              </Grid.Col>
            ))}
        </Grid>

        <BrowseDocumentsDrawer
          opened={drawerOpened}
          onClose={closeDrawer}
          documentsList={documentsList}
          selectedDocuments={selectedDocuments}
          onDocumentsSelect={setSelectedDocuments}
          isDocumentsLoading={loadingDocuments}
        />

        <Drawer
          opened={showTestResult}
          onClose={() => setShowTestResult(false)}
          title={`${
            selectedDocuments.find(
              (doc) => doc.id === documentTestResultsToView
            )?.file_original_name
          } - Test Results`}
          padding="xl"
          size="xl"
          position="right"
        >
          {documentTestResultsToView &&
            translateObjectToReadableFormat(
              docTestStatuses[documentTestResultsToView]?.rationales || []
            )}
        </Drawer>
      </Stack>
    </Box>
  );
}

export default RuleStudio;
