import { CSSVariablesResolver } from '@mantine/core';

export const resolver: CSSVariablesResolver = () => ({
  variables: {
    '--mantine-color-anchor': '#232323',
  },
  light: {
    '--mantine-color-anchor': '#232323',
  },
  dark: {
    '--mantine-color-anchor': '#fff',
  },
});
