import { useCallback, useEffect, useState } from 'react';

import { MantineProvider, createTheme } from '@mantine/core';

import { ThemeContext } from './ThemeContext';
import { resolver } from './resolver';
import {
  baseTheme,
  NAEHAS_COLORS,
  ZENAFIDE_COLORS,
  BRAND_ASSETS,
} from './theme.config';
import type { ThemeBrand, ThemeConfig } from './types';

const createCustomTheme = (brand: ThemeBrand) =>
  createTheme({
    ...baseTheme,
    primaryColor: 'primary',
    colors: brand === 'naehas' ? NAEHAS_COLORS : ZENAFIDE_COLORS,
  });

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [themeConfig, setThemeConfig] = useState<ThemeConfig>(() => ({
    brand: (localStorage.getItem('themeBrand') as ThemeBrand) || 'zenafide',
  }));

  const theme = createCustomTheme(themeConfig.brand);

  useEffect(() => {
    localStorage.setItem('themeBrand', themeConfig.brand);
  }, [themeConfig]);

  const setBrand = useCallback((brand: ThemeBrand) => {
    setThemeConfig((prev) => ({
      ...prev,
      brand,
    }));
  }, []);

  const setThemeByEmail = useCallback((email: string | undefined) => {
    console.log('email for theme', email);
    setThemeConfig((prev) => ({
      ...prev,
      brand:
        email?.includes('pathward') || email?.includes('naehas')
          ? 'naehas'
          : 'zenafide',
    }));
  }, []);

  const renderLogo = useCallback(
    (props?: { isWhite?: boolean; height?: number }) => {
      const logoConfig = BRAND_ASSETS[themeConfig.brand].getLogo(
        props?.isWhite ?? false
      );
      const defaultHeightByBrand =
        themeConfig.brand === 'naehas' ? 30 : props?.isWhite ? 20 : 30;
      return (
        <img
          height={props?.height ?? defaultHeightByBrand}
          style={{ cursor: 'pointer' }}
          src={logoConfig.src}
          alt={themeConfig.brand}
        />
      );
    },
    [themeConfig.brand]
  );

  const value = {
    ...themeConfig,
    setBrand,
    setThemeByEmail,
    renderLogo,
  };

  return (
    <ThemeContext.Provider value={value}>
      <MantineProvider theme={theme} cssVariablesResolver={resolver}>
        {children}
      </MantineProvider>
    </ThemeContext.Provider>
  );
};
