import {
  Stack,
  Box,
  Text,
  Timeline,
  Button,
  Card,
  Flex,
  Group,
  useMantineTheme,
} from '@mantine/core';
import { IconArrowNarrowRight, IconCircleDot } from '@tabler/icons-react';

const AssessmentSetup = ({ goToRuleSetup }: { goToRuleSetup: () => void }) => {
  const theme = useMantineTheme();

  return (
    <Flex px="xl" py="xl" direction="column" gap="xl" h="100%" w="100%">
      <Flex direction="column" gap="sm">
        <Box>
          <Text size="md" fw={500}>
            Hey, lets get you started! 🚀
          </Text>
          <Text size="sm" fw={500}>
            Two quick steps to run assessments
          </Text>
        </Box>
        <Box mt="xl">
          <Timeline bulletSize={28} lineWidth={2}>
            <Timeline.Item
              bullet={<IconCircleDot color={theme.colors.primary[9]} />}
            >
              <Card withBorder p="sm">
                <Stack gap="sm">
                  <Text c={'primary'} fw={500} size="sm">
                    Step 1
                  </Text>
                  <Text fw={500} size="sm">
                    Select and add rules for assessment ✅
                  </Text>
                  <Button
                    variant="contained"
                    size="xs"
                    w="fit-content"
                    onClick={goToRuleSetup}
                  >
                    Rule setup
                  </Button>
                </Stack>
              </Card>
            </Timeline.Item>

            <Timeline.Item
              bullet={<IconCircleDot color={theme.colors.gray[5]} />}
            >
              <Card withBorder p="md">
                <Stack gap="sm">
                  <Text c="primary" fw={500} size="sm">
                    Step 2
                  </Text>
                  <Text fw={500} size="sm">
                    Run Assessment ⚡
                  </Text>
                </Stack>
              </Card>
            </Timeline.Item>
          </Timeline>
        </Box>
      </Flex>
      <Flex
        style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
        w="100%"
        px="xl"
      >
        <Button variant="default" size="xl" fullWidth onClick={goToRuleSetup}>
          <Group gap="xs">
            <Text size="sm" fw={500}>
              Go to Rule setup
            </Text>
            <IconArrowNarrowRight stroke={1} />
          </Group>
        </Button>
      </Flex>
    </Flex>
  );
};

export default AssessmentSetup;
