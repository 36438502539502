import { DataTableColumn, DataTableProps } from 'mantine-datatable';

import { Box, Flex, useMantineTheme } from '@mantine/core';
import ToolBar from 'Components/toolbar/ToolBar';
import { FiltersType, FilterItemProps } from 'Types/commonTypes';

import DataTable from './DataTable';

type ToolBarConfigProps = {
  filtersConfig?: FilterItemProps[];
};

type TableWithFilters<T> = Omit<DataTableProps<T>, 'columns'> & {
  loading: boolean;
  records: T[];
  totalRecords: number;
  onSearch: (query: string) => void;
  columns: DataTableColumn<T>[];
  showSearch?: boolean;
  page: number;
  onPageChange: (page: number) => void;
  disableDataTable?: boolean;
  showFilterIcon?: boolean;
  onToggleFilters?: () => void;
  onApplyFilters?: (filters: FiltersType) => void;
  toolBarConfig?: ToolBarConfigProps;
  topActions?: React.ReactNode;
};

const TableWithFilters = <T,>({
  loading,
  records = [],
  columns = [],
  totalRecords,
  onSearch,
  showSearch = false,
  page,
  onPageChange,
  disableDataTable,
  showFilterIcon = false,
  onToggleFilters,
  onApplyFilters,
  toolBarConfig,
  topActions,
}: TableWithFilters<T>) => {
  const theme = useMantineTheme();
  const { filtersConfig = [] } = toolBarConfig || {};

  return (
    <Flex
      p="md"
      bg="white"
      direction="column"
      style={{ borderRadius: theme.radius.md }}
      h="100%"
    >
      <Flex pb="xs" align="center" justify="space-between">
        <ToolBar
          showSearch={showSearch}
          onSearch={onSearch}
          showFilterIcon={showFilterIcon}
          onToggleFilters={onToggleFilters}
          onApplyFilters={onApplyFilters}
          filtersConfig={filtersConfig}
          totalEntities={totalRecords}
        />
        {topActions && <Box>{topActions}</Box>}
      </Flex>

      <Flex flex={1} w="100%" style={{ overflowY: 'auto' }}>
        <DataTable
          withTableBorder={false}
          borderRadius="md"
          height="100%"
          striped
          width="100%"
          fetching={loading}
          highlightOnHover
          verticalSpacing="xs"
          horizontalSpacing="lg"
          records={records}
          columns={columns}
          totalRecords={totalRecords}
          paginationActiveBackgroundColor="grape"
          recordsPerPage={100}
          page={page}
          onPageChange={(currentPage) => {
            onPageChange(currentPage);
          }}
          disableDataTable={disableDataTable}
        />
      </Flex>
    </Flex>
  );
};

export default TableWithFilters;
