import { IconNotes, IconReceipt2, IconTag } from '@tabler/icons-react';
import { MenuItemType } from 'Types/commonTypes';

export const MENU: MenuItemType[] = [
  {
    label: 'Documents',
    icon: IconNotes,
    slug: 'documents',
    link: '/documents',
  },
  {
    label: 'Rules',
    icon: IconReceipt2,
    slug: 'rule-studio',
    link: '/rules',
  },
  {
    label: 'Tag Management',
    icon: IconTag,
    slug: 'tag-management',
    link: '/tag-management',
  },
];
