import { MantineColorsTuple, rem } from '@mantine/core';
import { Button, Loader } from '@mantine/core';
import RingLoader from 'Components/ring-loader/RingLoader';
import NaehasDarkLogo from 'Src/assets/naehas-dark-logo.png';
import NaehasWhiteLogo from 'Src/assets/naehas-white-logo.png';
import ZenafideWhiteLogo from 'Src/assets/zenafide-logo-white.svg';
import ZenafideLogo from 'Src/assets/zenafide-logo.png';

// Color type definitions
export type BrandColors = {
  primary: MantineColorsTuple;
  secondary: MantineColorsTuple;
  tertiary: MantineColorsTuple;
};

// Brand Colors
export const COLORS = {
  naehas: {
    primary: [
      '#e8fcfd',
      '#d9f3f5',
      '#b4e5ea',
      '#8bd7de',
      '#6bcbd4',
      '#57c4ce',
      '#003D4C',
      '#39aab3',
      '#2997a0',
      '#01838c',
    ] as MantineColorsTuple,
  },
  zenafide: {
    primary: [
      '#ffebf8',
      '#fad8e8',
      '#edadcd',
      '#e282b1',
      '#d85d99',
      '#d2458a',
      '#CD307D',
      '#b92970',
      '#a62164',
      '#931557',
    ] as MantineColorsTuple,
  },
  common: {
    grey: [
      '#FFF',
      '#FFF',
      '#F8F8F8',
      '#F8F8F8',
      '#BBB',
      '#BBB',
      '#757575',
      '#757575',
      '#000',
      '#000',
    ] as MantineColorsTuple,
    blue: [
      '#85939F',
      '#6B7C88',
      '#526571',
      '#384E5A',
      '#1F3743',
      '#091A29',
      '#081626',
      '#071222',
      '#060E1E',
      '#050A1B',
    ] as MantineColorsTuple,
  },
};

// Brand-specific theme colors
export const NAEHAS_COLORS: BrandColors = {
  primary: COLORS.naehas.primary,
  secondary: COLORS.common.grey,
  tertiary: COLORS.common.blue,
};

export const ZENAFIDE_COLORS: BrandColors = {
  primary: COLORS.zenafide.primary,
  secondary: COLORS.common.grey,
  tertiary: COLORS.common.blue,
};

// Base theme configuration
export const baseTheme = {
  scale: 1,
  focusRing: 'never' as const,
  fontFamily: 'Figtree',
  defaultRadius: 4,
  cursorType: 'pointer' as const,
  fontSizes: {
    xs: '0.75rem',
    sm: '1rem',
    md: '1.25rem',
    lg: '1.5rem',
    xl: '1.75rem',
  },
  headings: {
    fontWeight: '400',
    sizes: {
      h1: { fontSize: rem(35), lineHeight: 'normal' },
      h2: { fontSize: rem(30), lineHeight: 'normal' },
      h3: { fontSize: rem(25), lineHeight: 'normal' },
      h4: { fontSize: rem(20), lineHeight: 'normal', fontWeight: '500' },
      h5: { fontSize: rem(18), lineHeight: 'normal', fontWeight: '500' },
      h6: { fontSize: rem(16), lineHeight: 'normal' },
    },
  },
  components: {
    Button: Button.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    Loader: Loader.extend({
      defaultProps: {
        loaders: { ...Loader.defaultLoaders, ring: RingLoader },
        type: 'ring',
      },
    }),
  },
};

export const BRAND_ASSETS = {
  naehas: {
    logo: NaehasWhiteLogo,
    // Always use white logo with invert for dark/light modes
    getLogo: (isWhite = false) => ({
      src: isWhite ? NaehasWhiteLogo : NaehasDarkLogo,
    }),
  },
  zenafide: {
    getLogo: (isWhite = false) => ({
      src: isWhite ? ZenafideWhiteLogo : ZenafideLogo,
      height: 30,
    }),
  },
} as const;
