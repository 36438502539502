import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  Button,
  Flex,
  Grid,
  Group,
  Stack,
  Switch,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
  TextInput,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import CommonService from 'Api/commonService';
import { LinguisticErrors } from 'Constants/index';
import { setHighlightedCoordinates } from 'Slices/activeDocumentSlice';
import { RootState } from 'Src/redux/store';
import { LinguisticErrorType } from 'Types/linguisticTypes';
import { getDiscussionTitleAndContent, splitUsername } from 'Utils/helper';
import {
  showErrorNotification,
  showInfoNotification,
} from 'Utils/notifications';

import classes from './GrammaticalSpellCheck.module.scss';
import { linguisticErrorsAtom } from './spellingGrammarAtom';

interface GrammaticalSectionProps {
  handleErrorAssignment: (snapshotId: number, errorId: number) => void;
  ignoreErrors: (
    errors: Array<{ snapshotId: number; errorId: number }>
  ) => void;
}

const GrammaticalSection: React.FC<GrammaticalSectionProps> = ({
  handleErrorAssignment,
  ignoreErrors,
}) => {
  const linguisticErrors = useAtomValue(linguisticErrorsAtom);
  const [grammarErrors, setGrammarErrors] = useState<LinguisticErrorType[]>([]);
  const dispatch = useDispatch();
  const theme = useMantineTheme();
  const documentData = useSelector(
    (state: RootState) => state.activeDocument.data
  );

  console.log(linguisticErrors, 'lingusicErrors');

  useEffect(() => {
    const onlyGrammarErrors = linguisticErrors.filter(
      (error) =>
        error.error_type === LinguisticErrors.GRAMMAR_ERROR && !error.is_ignored
    );
    setGrammarErrors(onlyGrammarErrors);
  }, [linguisticErrors]);

  const handleHighlight = (error: LinguisticErrorType) => {
    // currently we are only highlighting the first word block
    if (!(error && error.word_blocks[0]?.bounding_box)) {
      return showErrorNotification(
        'Unable to highlight text: The text is either non-searchable or part of an image.'
      );
    }
    const coordinates = error.word_blocks.map((block) => ({
      ...block.bounding_box,
      page: block.page_number,
    }));
    dispatch(setHighlightedCoordinates(coordinates));
  };

  const addWordToDictionary = async (errorId: number, word: string) => {
    try {
      if (documentData === null || documentData.org_id === undefined) return;

      await CommonService.addToDictionary({
        word,
        org: documentData.org_id,
        mark_as_ignored: [errorId],
      });
      showInfoNotification(
        'Word added to dictionary',
        'Updating the grammar check list...'
      );
    } catch (error: any) {
      showErrorNotification(
        error?.message || 'Error adding word to dictionary'
      );
    }
  };

  const addToDictionaryModal = (errorId: number) =>
    modals.open({
      title: <Title order={5}>Add to dictionary</Title>,
      variant: 'pink-line',
      size: 'sm',
      children: (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const word = formData.get('word') as string;
            if (word) {
              addWordToDictionary(errorId, word);
              modals.closeAll();
            }
          }}
        >
          <Stack>
            <Text size="sm">
              Enter the word you want to add to your dictionary:
            </Text>
            <TextInput
              name="word"
              placeholder="Enter word"
              required
              data-autofocus
              size="sm"
            />
            <Group justify="flex-end" mt="md">
              <Button
                variant="transparent"
                onClick={() => modals.closeAll()}
                size="xs"
              >
                Cancel
              </Button>
              <Button type="submit" size="xs" variant="filled-shadow">
                Add to Dictionary
              </Button>
            </Group>
          </Stack>
        </form>
      ),
    });

  return (
    <Accordion.Item value="grammatical">
      <Accordion.Control>
        <Flex mr="md" justify="space-between" align="center">
          <Text tt="uppercase" size="xs" mr="sm">
            Grammatical ({grammarErrors.length})
          </Text>
          <Text size="xs" c={theme.colors.primary[6]}>
            6 results awaiting response
          </Text>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel>
        {grammarErrors.map((error) => (
          <Stack
            className={classes.grammaticalErrorCard}
            gap="xs"
            pb="xs"
            style={{ borderBottom: '1px solid #e0e0e0' }}
            key={error.id}
            p="md"
            onClick={() => handleHighlight(error)}
          >
            <Grid align="center">
              <Grid.Col span={9}>
                <Tooltip
                  w={250}
                  label={error.error_message}
                  multiline
                  withArrow
                  position="top"
                >
                  <Text size="sm" mr="sm" lineClamp={1}>
                    {error.error_message}
                  </Text>
                </Tooltip>
              </Grid.Col>
              <Grid.Col span={3}>
                <Switch
                  size="xs"
                  labelPosition="left"
                  label="Ignore"
                  checked={error.is_ignored}
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      ignoreErrors([
                        {
                          snapshotId: error.document_snapshot_id,
                          errorId: error.id,
                        },
                      ]);
                    }
                  }}
                />
              </Grid.Col>
            </Grid>
            <Stack gap={0}>
              <Text c="#069B03" size="xs" fw={500}>
                Suggestions:{' '}
              </Text>
              <Text size="sm" c="#666">
                {error.suggested_corrections
                  .map((suggestion) => suggestion)
                  .join(', ')}
              </Text>
            </Stack>
            {error.comment_thread_id && (
              <Tooltip
                label={getDiscussionTitleAndContent(error)?.firstPostContent}
              >
                <Text mt="xs" size="sm" fs="italic" c="#666">
                  <span style={{ fontWeight: 500, color: '#008BD9' }}>
                    {
                      splitUsername(getDiscussionTitleAndContent(error)?.title)
                        .username
                    }
                  </span>
                  <span>
                    {
                      splitUsername(getDiscussionTitleAndContent(error)?.title)
                        .rest
                    }
                  </span>
                </Text>
              </Tooltip>
            )}
            <Group gap="xl">
              <Button
                size="xs"
                variant="link"
                onClick={() =>
                  handleErrorAssignment(error.document_snapshot_id, error.id)
                }
              >
                Assign User
              </Button>
              <Button
                size="xs"
                variant="link"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  addToDictionaryModal(error.id);
                }}
              >
                Add to Dictionary
              </Button>
            </Group>
          </Stack>
        ))}
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default GrammaticalSection;
