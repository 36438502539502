import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';

import { Flex, Text, Card, Box, Tabs } from '@mantine/core';
import AuditLogs from 'Components/activity-log/AuditLogs';
import LoadingOverlay from 'Components/loading-overlay/LoadingOverlay';
import { AssessmentTabs, LoadingStatus } from 'Constants/index';
import { RootState } from 'Src/redux/store';
import { RuleEvalType } from 'Types/ruleTypes';

import classes from './../Assessment.module.scss';
import AssessmentTab from './assessment-tab/AssessmentTab';
import RuleSetupTab from './rule-setup-tab/RuleSetup';

type TabsTypes = {
  toggleComments: Dispatch<SetStateAction<boolean>>;
};

const TabsContainer: React.FC<TabsTypes> = ({ toggleComments }) => {
  const assessmentData = useSelector(
    (state: RootState) => state.activeAssessment.data
  );
  const assessmentDataLoading = useSelector(
    (state: RootState) => state.activeAssessment.loading
  );
  const [activeTab, setActiveTab] = useState<string>('assessment');

  useEffect(() => {
    if (activeTab === 'activity') return;
    if (assessmentData && assessmentData.report_id && assessmentData.id) {
      setActiveTab(AssessmentTabs.ASSESSMENT_TAB);
    } else {
      setActiveTab(AssessmentTabs.SETUP_TAB);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [assessmentData]);

  const ruleEvalsString = useCallback(() => {
    const ruleEvals = assessmentData?.report?.rule_evals;
    return ruleEvals && ruleEvals.length > 0
      ? ruleEvals
          .map((ruleEval: RuleEvalType) => `rules.ruleeval.${ruleEval.id}`)
          .join(',')
      : 'rules.ruleeval';
  }, [assessmentData?.report?.rule_evals]);

  const tabsData = [
    { label: 'Assessment', value: 'assessment' },
    { label: 'Rule Setup', value: 'setup' },
    { label: 'Activity log', value: 'activity' },
  ];

  const handleTabChange = (value: string | null) => {
    if (value !== null) {
      setActiveTab(value);
    }
  };

  return [LoadingStatus.IDLE, LoadingStatus.LOADING].includes(
    assessmentDataLoading
  ) ? (
    <Flex style={{ height: '100%' }} align="center" justify="center">
      <LoadingOverlay visible={true} />
    </Flex>
  ) : (
    <Tabs
      variant="pills"
      defaultValue="setup"
      value={activeTab}
      onChange={handleTabChange}
      style={{ width: '100%' }}
      keepMounted={false}
    >
      <Flex direction="column" w={'100%'} h={'100%'}>
        <Box
          w={'100%'}
          pos={'sticky'}
          top={'0'}
          bg="white"
          style={{ zIndex: 99 }}
        >
          <Card
            p={4}
            withBorder
            radius={0}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Tabs.List>
              {tabsData.map((tab, index) => {
                return (
                  <Tabs.Tab
                    color="white"
                    value={tab.value}
                    className={classes.tab}
                    key={index}
                  >
                    <Text
                      c="black"
                      size="sm"
                      fw={activeTab === tab.value ? 700 : 500}
                    >
                      {tab.label}
                    </Text>
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>
          </Card>
          <Flex flex={1}>
            <Tabs.Panel value="assessment" w="100%">
              <AssessmentTab
                changeMainTab={(tab) => setActiveTab(tab)}
                toggleComments={toggleComments}
              />
            </Tabs.Panel>
            <Tabs.Panel value="setup" w="100%">
              <RuleSetupTab changeActiveTab={(tab) => setActiveTab(tab)} />
            </Tabs.Panel>
            <Tabs.Panel value="activity" w="100%">
              <AuditLogs
                isDrawer={false}
                open={activeTab === 'activity'}
                contentType={ruleEvalsString()}
                entities={{
                  list: assessmentData?.report.rule_evals || [],
                  key: 'rule.title',
                  currentEntity: null,
                  contentType: 'rules.ruleeval',
                  name: 'Rule Eval',
                }}
              />
            </Tabs.Panel>
          </Flex>
        </Box>
      </Flex>
    </Tabs>
  );
};

export default TabsContainer;
