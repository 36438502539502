import { createContext, useContext } from 'react';

// import { MantineTheme } from '@mantine/core';
import type { ThemeConfig, ThemeBrand } from './types';

// All the theme stuff we need across the app
interface ThemeContextType extends ThemeConfig {
  setBrand: (brand: ThemeBrand) => void;
  setThemeByEmail: (email: string | undefined) => void;
  renderLogo: (props?: { isWhite?: boolean; height?: number }) => JSX.Element;
  //   currentTheme: MantineTheme;
}

// Main theme context - starts empty, filled by ThemeProvider
export const ThemeContext = createContext<ThemeContextType | undefined>(
  undefined
);

// Quick way to use theme stuff anywhere in the app
// Just do: const { brand } = useTheme();
export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('Hey! useTheme needs to be inside ThemeProvider');
  }

  return context;
};
