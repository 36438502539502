import { useRef, useState } from 'react';

import {
  Drawer,
  TextInput,
  Group,
  Text,
  Stack,
  Button,
  Box,
  Checkbox,
  Flex,
  LoadingOverlay,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { DocumentSnapshotType } from 'Types/docTypes';

interface BrowseDocumentsDrawerProps {
  opened: boolean;
  onClose: () => void;
  documentsList: DocumentSnapshotType[];
  selectedDocuments: DocumentSnapshotType[];
  onDocumentsSelect: (documents: DocumentSnapshotType[]) => void;
  isDocumentsLoading?: boolean;
}

export function BrowseDocumentsDrawer({
  opened,
  onClose,
  documentsList,
  selectedDocuments,
  onDocumentsSelect,
  isDocumentsLoading,
}: BrowseDocumentsDrawerProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  const filteredDocuments = documentsList.filter((doc) =>
    doc.file_original_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useHotkeys([
    [
      'mod+F',
      (e) => {
        e.preventDefault();
        searchInputRef.current?.focus();
      },
    ],
  ]);

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      styles={{
        header: {
          padding: '20px',
          borderBottom: '1px solid #E9ECEF',
        },
      }}
      title={
        <Stack>
          <Text size="md">Attach Documents for testing</Text>
          <Flex
            justify="space-between"
            align="center"
            gap="lg"
            style={{ width: '100%' }}
          >
            <TextInput
              style={{ width: '300px' }}
              size="xs"
              ref={searchInputRef}
              placeholder="Search..."
              leftSection={<IconSearch size={16} />}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.currentTarget.value)}
            />
            {selectedDocuments.length > 0 && (
              <Group gap="xs">
                <Button onClick={onClose} variant="outline" size="xs">
                  {selectedDocuments.length} Attach Selected
                </Button>
                <Button
                  onClick={() => onDocumentsSelect([])}
                  variant="transparent"
                  size="xs"
                >
                  Clear Selected
                </Button>
              </Group>
            )}
          </Flex>
        </Stack>
      }
      padding="xl"
      size="xl"
      position="right"
    >
      {isDocumentsLoading && <LoadingOverlay visible={isDocumentsLoading} />}
      <Stack mih="50vh" pt="lg">
        {filteredDocuments.map((doc) => (
          <Box mb="sm" key={doc.id}>
            <Group>
              <Checkbox
                checked={selectedDocuments.some(
                  (selected) => selected.id === doc.id
                )}
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    const documentToAdd = documentsList.find(
                      (document) => document.id === doc.id
                    );
                    if (documentToAdd) {
                      onDocumentsSelect([...selectedDocuments, documentToAdd]);
                    }
                  } else {
                    onDocumentsSelect(
                      selectedDocuments.filter(
                        (selected) => selected.id !== doc.id
                      )
                    );
                  }
                }}
                label={<Text size="sm">{doc.file_original_name}</Text>}
              />
            </Group>
          </Box>
        ))}
      </Stack>
    </Drawer>
  );
}
