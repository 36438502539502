import cx from 'clsx';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  Burger,
  Group,
  UnstyledButton,
  Menu,
  useMantineTheme,
  Center,
} from '@mantine/core';
import {
  IconChevronDown,
  IconHome,
  IconBrowser,
  IconNote,
  IconReceipt2,
  IconLogout,
} from '@tabler/icons-react';
import { AuthService } from 'Api/authService';
import UserLabel from 'Components/user-label/UserLabel';
import { logout } from 'Slices/authSlice';
// import { RootState } from 'Src/redux/store';
import { useTheme } from 'Src/theme';

import classes from './IRNavbar.module.scss';

type IRNavbarProps = {
  handleDesktopOpened?: { toggle: () => void };
  desktopOpened?: boolean;
};

const links = [
  { icon: IconHome, link: '/', label: 'Dashboard' },
  {
    link: '#1',
    icon: IconBrowser,
    label: 'Application',
    links: [
      { icon: IconNote, link: '/documents', label: 'Documents' },
      { icon: IconReceipt2, link: '/rules', label: 'Rules' },
    ],
  },
];

export function HeaderMenu() {
  const items = links.map((link, index) => {
    const menuItems = link.links?.map((item, nestedIndex) => (
      <Menu.Item key={`${index}${nestedIndex}`}>
        <Group align="center" gap={4}>
          <span>{<item.icon />}</span>
          <Link key={item.label} to={item.link}>
            {item.label}
          </Link>
        </Group>
      </Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu
          key={index}
          trigger="hover"
          transitionProps={{ exitDuration: 0 }}
          withinPortal
        >
          <Menu.Target>
            <Link to={link.link} className={classes.link}>
              <Center>
                <Group gap={4} align="center">
                  <span>{<link.icon className={classes.menuIcon} />}</span>
                  <span className={classes.linkLabel}>{link.label}</span>
                </Group>
                <IconChevronDown size="0.9rem" stroke={1.5} />
              </Center>
            </Link>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <Group key={index} align="center" gap={4}>
        <span>{<link.icon className={classes.menuIcon} />}</span>
        <Link key={link.label} to={link.link} className={classes.link}>
          {link.label}
        </Link>
      </Group>
    );
  });

  return (
    <Group gap="xl" visibleFrom="sm">
      {items}
    </Group>
  );
}

const IRNavbar: React.FC<IRNavbarProps> = ({
  handleDesktopOpened,
  desktopOpened,
}) => {
  // const { setColorScheme, colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const { renderLogo } = useTheme();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const user = useSelector((state: RootState) => state.auth.user);

  const handleLogout = async () => {
    try {
      setUserMenuOpened(false);
      await AuthService.logout();
      dispatch(logout());
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  const menuItems = [
    { icon: IconLogout, text: 'Logout', onClick: handleLogout },
  ];

  return (
    <Group
      className={classes.navbar}
      align="center"
      h="100%"
      px="md"
      justify="space-between"
    >
      <Group align="center">
        {handleDesktopOpened && (
          <Burger
            opened={desktopOpened}
            onClick={handleDesktopOpened.toggle}
            visibleFrom="sm"
            size="sm"
          />
        )}
        <Group align="center">{renderLogo({ isWhite: true })}</Group>
      </Group>
      <Group justify="space-between" style={{ flex: 1 }}>
        <Group ml="xl" gap={30} visibleFrom="sm">
          <HeaderMenu />
        </Group>
      </Group>
      <Group align="center" justify="space-around">
        <Menu
          width={260}
          position="bottom-end"
          transitionProps={{ transition: 'pop-top-right' }}
          onClose={() => setUserMenuOpened(false)}
          onOpen={() => setUserMenuOpened(true)}
          withinPortal
        >
          <Menu.Target>
            <UnstyledButton
              className={cx(classes.user, {
                [classes.userActive]: userMenuOpened,
              })}
            >
              <Group align="center" gap="xs">
                <UserLabel color="white" />
                <IconChevronDown color="white" size="0.9rem" stroke={1.5} />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            {menuItems.map((item, index) => (
              <Menu.Item
                key={index}
                leftSection={
                  <item.icon
                    className={classes.icon}
                    color={theme.colors.gray[6]}
                    stroke={1.5}
                  />
                }
                {...(item.onClick ? { onClick: item.onClick } : {})}
              >
                {item.text}
              </Menu.Item>
            ))}
            {/* since we don't support dark mode, remove it from the menu */}
            {/* <Menu.Item
              onClick={() =>
                setColorScheme(colorScheme === 'dark' ? 'light' : 'dark')
              }
              leftSection={
                <>
                  <IconSun
                    className={cx(classes.icon, classes.light)}
                    stroke={1.5}
                  />
                  <IconMoon
                    className={cx(classes.icon, classes.dark)}
                    stroke={1.5}
                  />
                </>
              }
            >
              {colorScheme === 'dark' ? 'Light Mode' : 'Dark Mode'}
            </Menu.Item> */}
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Group>
  );
};

export default IRNavbar;
