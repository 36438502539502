import { LinguisticErrorType } from 'Types/linguisticTypes';

const getDiscussionTitleAndContent = (data: LinguisticErrorType) => {
  try {
    // Extract discussion details
    if (data.comments !== undefined) {
      const discussion = data.comments.discussion || {};
      const title = discussion.title || '';

      // Extract latest post details
      const latestPosts = discussion.latest_posts || [];
      const firstPostContent =
        latestPosts.length > 0 ? latestPosts[0].content || '' : '';

      return { title, firstPostContent };
    }
  } catch (error) {
    console.error('Error extracting discussion details', error);
  }
};

const splitUsername = (content: string = '') => {
  if (!content || content === '') return { username: '', rest: '' };
  const [firstPart, ...restParts] = content.split(' ');
  const restContent = restParts.join(' ');
  return { username: firstPart, rest: ` ${restContent}` };
};

const getNameFromEmail = (email: string): string => {
  if (!email) return '';

  // Check if string contains @ symbol
  const atIndex = email.indexOf('@');
  if (atIndex === -1) return email;

  // Return everything before @ symbol
  return email.substring(0, atIndex);
};

export { getDiscussionTitleAndContent, splitUsername, getNameFromEmail };
