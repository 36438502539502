import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Text, Center, Container, Loader, Flex } from '@mantine/core';
import { login } from 'Slices/authSlice';
import useLoading from 'Src/hooks/useLoading';

const LandingIframe = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, handleLoading] = useLoading(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const TOKEN =
    'e25db6e16e0d0630c6be2f3dd0188f046a3867410af30ebce00b3b98eda4b10b';

  const dummyAuthTokenResponse = {
    expiry: '2025-02-20T18:53:07.480367Z',
    token: TOKEN,
    user: {
      id: 19,
      username: 'amogh@demo.zenafide.com',
      email: 'amogh@demo.zenafide.com',
      first_name: 'Amogh',
      last_name: 'Dikshit',
      expiry: '2025-02-20T18:53:07.480367Z',
      token: TOKEN,
      tenants: [],
      permissions: [],
    },
    tenants: [
      {
        id: 8,
        name: '[Demo] Pathward',
        is_active: true,
        slug: 'demo-pathward',
      },
    ],
    permissions: [],
  };

  const processToken = async () => {
    try {
      // currently fetching from params but later on can move the values
      // like authtoken in post message
      // just keep on adding updated token in the url params else it will redirect to login page
      const token = TOKEN;
      const redirectTo = searchParams.get('redirectTo') || '/rules';
      //   todo :
      //   make BE api call to validate the token and fetch user details
      //   once the details is present dispatch login data
      //   then navigate to the redirectTo path
      if (!token || !redirectTo) {
        throw new Error('No auth token or redirectTo path provided');
      }
      await dispatch(login({ ...dummyAuthTokenResponse, token }));
      // the host application is required to give exact path
      console.log('now navigating to', redirectTo);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      navigate(`${redirectTo}`);
      handleLoading.stop();
    } catch (err) {
      console.error('Error processing token:', err);
      setError(err instanceof Error ? err.message : 'Failed to process token');
    } finally {
      handleLoading.stop();
    }
  };

  useEffect(() => {
    processToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Container p="md">
      <Center h="70vh" style={{ flexDirection: 'column', gap: '1rem' }}>
        {isLoading && (
          <Flex direction="column" justify="center" align="center">
            <Loader size="xl" />
            <Text size="xl">Loading</Text>
          </Flex>
        )}
        {error && <Text size="xl">Error: {error}</Text>}
      </Center>
    </Container>
  );
};

export default LandingIframe;
