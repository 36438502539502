import { useAtomValue } from 'jotai';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  Box,
  Button,
  Flex,
  Group,
  Stack,
  Switch,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
  ActionIcon,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconArrowUp, IconArrowDown } from '@tabler/icons-react';
import CommonService from 'Api/commonService';
import { LinguisticErrors } from 'Constants/index';
import { setHighlightedCoordinates } from 'Slices/activeDocumentSlice';
import { RootState } from 'Src/redux/store';
import { TextCoordinatesType } from 'Types/fileViewerTypes';
import { LinguisticErrorType } from 'Types/linguisticTypes';
import { getDiscussionTitleAndContent, splitUsername } from 'Utils/helper';
import {
  showErrorNotification,
  showInfoNotification,
} from 'Utils/notifications';

import classes from './GrammaticalSpellCheck.module.scss';
import { linguisticErrorsAtom } from './spellingGrammarAtom';

interface SpellCheckSectionProps {
  handleErrorAssignment: (snapshotId: number, errorId: number) => void;
  ignoreErrors: (
    errors: Array<{ snapshotId: number; errorId: number }>
  ) => void;
}

const SpellCheckSection: React.FC<SpellCheckSectionProps> = ({
  handleErrorAssignment,
  ignoreErrors,
}) => {
  const dispatch = useDispatch();
  const [groupedErrors, setGroupedErrors] = useState<
    Record<string, LinguisticErrorType[]>
  >({});
  const theme = useMantineTheme();
  const linguisticErrors = useAtomValue(linguisticErrorsAtom);
  const documentData = useSelector(
    (state: RootState) => state.activeDocument.data
  );
  const [currentErrorId, setCurrentErrorId] = useState<number | null>(null);
  const [expandedAccordions, setExpandedAccordions] = useState<Set<number>>(
    new Set()
  );

  const groupErrorsByText = (errors: LinguisticErrorType[]) => {
    return errors.reduce(
      (acc, error) => {
        const key = error.word_blocks.map((block) => block.text).join('|');
        if (acc[key]) {
          acc[key].push(error);
        } else {
          acc[key] = [error];
        }
        return acc;
      },
      {} as Record<string, LinguisticErrorType[]>
    );
  };

  useEffect(() => {
    const spellErrorsOnly = linguisticErrors.filter(
      (error) =>
        error.error_type === LinguisticErrors.SPELL_ERROR && !error.is_ignored
    );
    const groupedErrors = groupErrorsByText(spellErrorsOnly);
    setGroupedErrors(groupedErrors);
  }, [linguisticErrors]);

  const handleHighlight = (error: LinguisticErrorType) => {
    // currently we are only highlighting the first word block
    if (!(error && error.word_blocks[0]?.bounding_box)) {
      return showErrorNotification(
        'Unable to highlight text: The text is either non-searchable or part of an image.'
      );
    }
    const coordinates = {
      ...error.word_blocks[0].bounding_box,
      page: error.word_blocks[0].page_number,
    } as TextCoordinatesType;
    dispatch(setHighlightedCoordinates([coordinates]));
  };

  const addWordToDictionary = async (errorId: number, word: string) => {
    try {
      if (documentData === null || documentData.org_id === undefined) return;

      CommonService.addToDictionary({
        word,
        org: documentData.org_id,
        mark_as_ignored: [errorId],
      });
      showInfoNotification(
        'Word added to dictionary',
        'Updating the spell check list...'
      );
      // remove the word from the grouped errors
      const clonedGroupedErrors = cloneDeep(groupedErrors);
      delete clonedGroupedErrors[word];
      setGroupedErrors(clonedGroupedErrors);
    } catch (error: any) {
      showErrorNotification(
        error?.message || 'Error adding word to dictionary'
      );
    }
  };

  const addToDictionaryModal = (errorId: number, word: string) =>
    modals.openConfirmModal({
      title: <Title order={4}>Add to dictionary</Title>,
      variant: 'pink-line',
      size: 'md',
      children: (
        <Text size="sm">
          Are you sure you want to add <strong>"{word}"</strong> to your
          dictionary?
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      cancelProps: { size: 'sm', variant: 'transparent' },
      confirmProps: { size: 'sm', variant: 'filled-shadow' },
      onConfirm: () => addWordToDictionary(errorId, word),
    });

  // Get all errors for the current word, excluding the current error
  const getAllErrorsForWord = (error_word: string, currentErrorId: number) => {
    const allErrors = groupedErrors[error_word] || [];
    return allErrors.filter((error) => error.id !== currentErrorId);
  };

  const toggleAccordion = (errorId: number, isOpen: boolean) => {
    setExpandedAccordions((prev) => {
      const newSet = new Set(prev);
      if (isOpen) {
        newSet.add(errorId);
      } else {
        newSet.delete(errorId);
      }
      return newSet;
    });
  };

  const errorCards = Object.keys(groupedErrors).map((error_word) => {
    const currentError = groupedErrors[error_word][0];
    const similarErrors = getAllErrorsForWord(error_word, currentError.id);
    const hasSimilarErrors = similarErrors.length > 0;

    return (
      <Box key={`error-card-${currentError.id}`}>
        <Stack
          className={classes.grammaticalErrorCard}
          gap="xs"
          pb="xs"
          style={{ borderBottom: '1px solid #e0e0e0' }}
          p="md"
          onClick={() => handleHighlight(currentError)}
        >
          <Flex align="center" justify="space-between">
            <Text fz={16} mr="sm" fw={500} lineClamp={1}>
              "{currentError.word_blocks[0].text}"
            </Text>
            <Switch
              size="xs"
              labelPosition="left"
              label="Ignore"
              checked={currentError.is_ignored}
              onChange={(event) => {
                if (event.currentTarget.checked) {
                  ignoreErrors([
                    {
                      snapshotId: currentError.document_snapshot_id,
                      errorId: currentError.id,
                    },
                  ]);
                }
              }}
            />
          </Flex>
          <Stack gap={10}>
            <Text size="12px" c="#666">
              <Text c="#069B03" fw={500} span>
                Suggestions:{' '}
              </Text>
              {currentError.suggested_corrections
                .map((suggestion) => suggestion)
                .join(', ')}
            </Text>
          </Stack>
          {currentError.comment_thread_id && (
            <Tooltip
              label={
                getDiscussionTitleAndContent(currentError)?.firstPostContent
              }
            >
              <Text mt="xs" size="sm" fs="italic" c="#666">
                <span style={{ fontWeight: 500, color: '#008BD9' }}>
                  {
                    splitUsername(
                      getDiscussionTitleAndContent(currentError)?.title
                    ).username
                  }
                </span>
                <span>
                  {
                    splitUsername(
                      getDiscussionTitleAndContent(currentError)?.title
                    ).rest
                  }
                </span>
              </Text>
            </Tooltip>
          )}
          <Flex justify="space-between" align="center">
            <Group gap="sm">
              <Button
                onClick={() =>
                  handleErrorAssignment(
                    currentError.document_snapshot_id,
                    currentError.id
                  )
                }
                size="xs"
                variant="link"
              >
                Assign User
              </Button>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation(); // Stop event bubbling
                  const errorWord = currentError.word_blocks[0];
                  if (errorWord && errorWord.text) {
                    addToDictionaryModal(currentError.id, errorWord.text);
                  } else {
                    showErrorNotification(
                      'Error adding to dictionary, No word Found !'
                    );
                  }
                }}
                size="xs"
                variant="link"
              >
                Add to Dictionary
              </Button>
            </Group>
          </Flex>
        </Stack>
        {hasSimilarErrors && (
          <Accordion
            bd="1px solid #dee2e6"
            style={{ borderTop: 'unset', borderBottom: 'unset' }}
            onChange={(value) => toggleAccordion(currentError.id, !!value)}
          >
            <Accordion.Item value="similar-errors">
              <Accordion.Control
                bg="#FFF9F2"
                style={{
                  pointerEvents: 'none',
                }}
              >
                <Flex justify="space-between" align="center" w="100%">
                  <Group>
                    <Text size="12px">
                      {similarErrors.length} similar spell checks found{' '}
                    </Text>
                    <Group>
                      {expandedAccordions.has(currentError.id) ? (
                        <Group gap="sm">
                          <Tooltip label="Previous error">
                            <ActionIcon
                              size="sm"
                              disabled={
                                similarErrors.findIndex(
                                  (error) =>
                                    error.id ===
                                    (currentErrorId || currentError.id)
                                ) === 0
                              }
                              style={{
                                pointerEvents: 'auto',
                                borderRadius: '50%',
                                border: '1px solid',
                              }}
                              variant="subtle"
                              onClick={(e) => {
                                e.stopPropagation();
                                const currentIndex = similarErrors.findIndex(
                                  (error) =>
                                    error.id ===
                                    (currentErrorId || currentError.id)
                                );
                                if (currentIndex > 0) {
                                  const prevError =
                                    similarErrors[currentIndex - 1];
                                  handleHighlight(prevError);
                                  setCurrentErrorId(prevError.id);
                                }
                              }}
                            >
                              <IconArrowUp size={16} />
                            </ActionIcon>
                          </Tooltip>

                          <Tooltip label="Next error">
                            <ActionIcon
                              size="sm"
                              variant="subtle"
                              onClick={(e) => {
                                e.stopPropagation();
                                const currentIndex = similarErrors.findIndex(
                                  (error) =>
                                    error.id ===
                                    (currentErrorId || currentError.id)
                                );
                                if (currentIndex < similarErrors.length - 1) {
                                  const nextError =
                                    similarErrors[currentIndex + 1];
                                  handleHighlight(nextError);
                                  setCurrentErrorId(nextError.id);
                                }
                              }}
                              style={{
                                pointerEvents: 'auto',
                                borderRadius: '50%',
                                border: '1px solid',
                              }}
                              disabled={
                                similarErrors.findIndex(
                                  (error) =>
                                    error.id ===
                                    (currentErrorId || currentError.id)
                                ) ===
                                similarErrors.length - 1
                              }
                            >
                              <IconArrowDown size={16} />
                            </ActionIcon>
                          </Tooltip>
                        </Group>
                      ) : null}
                    </Group>
                  </Group>
                  <Group
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <Button
                      variant="outline"
                      size="compact-xs"
                      style={{
                        pointerEvents: 'auto',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        modals.openConfirmModal({
                          title: 'Ignore Similar Errors',
                          children: (
                            <Text size="sm">
                              Are you sure? This will ignore all similar errors.
                            </Text>
                          ),
                          labels: { confirm: 'Yes', cancel: 'No' },
                          confirmProps: { size: 'xs' },
                          cancelProps: { size: 'xs' },
                          onConfirm: () => {
                            const errorsToIgnore = [
                              // Map similar errors
                              ...similarErrors.map((error) => ({
                                snapshotId: error.document_snapshot_id,
                                errorId: error.id,
                              })),
                              // Add main error
                              {
                                snapshotId: currentError.document_snapshot_id,
                                errorId: currentError.id,
                              },
                            ];

                            ignoreErrors(errorsToIgnore);
                          },
                        });
                      }}
                      disabled={similarErrors.every(
                        (error) => error.is_ignored
                      )}
                    >
                      Ignore All
                    </Button>
                    <Text size="xs" pr="sm" c="#008BD9">
                      {expandedAccordions.has(currentError.id)
                        ? 'Hide'
                        : 'Show All'}
                    </Text>
                  </Group>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Stack gap={0}>
                  {similarErrors.map((similarError, index) => (
                    <Flex
                      key={`similar-error-${similarError.id}`}
                      className={`${classes.grammaticalErrorCard} ${
                        currentErrorId === similarError.id
                          ? classes.highlightedError
                          : ''
                      }`}
                      align="center"
                      justify="space-between"
                      onClick={() => {
                        handleHighlight(similarError);
                        setCurrentErrorId(similarError.id);
                      }}
                      px="md"
                      py={6}
                    >
                      <Group align="center" gap="sm">
                        <Flex
                          h={10}
                          p="sm"
                          w={10}
                          justify="center"
                          align="center"
                          style={{
                            borderRadius: '50%',
                            border: '1px solid',
                          }}
                        >
                          <Text size="xs">{index + 1}</Text>
                        </Flex>
                        <Text
                          size="sm"
                          fw={currentErrorId === similarError.id ? 500 : 400}
                        >
                          {similarError.word_blocks[0].text}
                        </Text>
                      </Group>
                      <Group gap="sm" align="center">
                        <Button
                          onClick={() =>
                            handleErrorAssignment(
                              currentError.document_snapshot_id,
                              currentError.id
                            )
                          }
                          size="sm"
                          variant="link"
                          style={{ fontSize: '10px', fontWeight: 600 }}
                        >
                          Assign User
                        </Button>
                      </Group>
                    </Flex>
                  ))}
                </Stack>
                <Flex justify="flex-end"></Flex>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        )}
      </Box>
    );
  });

  return (
    <Accordion.Item value="spell-check">
      <Accordion.Control>
        <Flex mr="md" justify="space-between" align="center">
          <Text tt="uppercase" size="12px" c="#757575" mr="sm">
            SPELL CHECK ({Object.keys(groupedErrors).length})
          </Text>
          <Text size="xs" c={theme.colors.primary[6]}>
            1 results awaiting response
          </Text>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel>{errorCards}</Accordion.Panel>
    </Accordion.Item>
  );
};

export default SpellCheckSection;
