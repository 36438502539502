import { DataTableColumn } from 'mantine-datatable';

import { Badge, Text, Flex, Menu, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconDotsVertical,
  IconExternalLink,
  IconFileInvoice,
} from '@tabler/icons-react';
import TableWithFilters from 'Components/data-table/TableWithFilters';
import { RuleFilters } from 'Constants/index';
import { FiltersType } from 'Src/types/commonTypes';
import { RuleType } from 'Src/types/ruleTypes';
import { transformDateString } from 'Src/utils/transform';

// type OpenActivityLogFunc = (ruleId: number) => void;

type ListRulesProps = {
  rules: RuleType[];
  onPageChange: (page: number) => void;
  loadingRules: boolean;
  totalRules: number;
  page: number;
  onSearch: (value: string) => void;
  onApplyFilters?: (filters: FiltersType) => void;
  topActions?: React.ReactNode;
};

const ListRules: React.FC<ListRulesProps> = ({
  rules = [],
  onPageChange,
  loadingRules,
  totalRules,
  page,
  onSearch,
  onApplyFilters,
  topActions,
}) => {
  const [disableDataTable, handleDisableDataTable] = useDisclosure(false);

  const columns: DataTableColumn<RuleType>[] = [
    {
      accessor: 'title',
      title: 'Rule Name',
      width: '40%',
      render: (rule) => (
        <Group gap="sm" style={{ cursor: 'pointer' }}>
          <IconFileInvoice size={16} stroke={1.5} color="gray" />
          <Text size="sm">
            {rule.title}{' '}
            <IconExternalLink
              size={12}
              style={{
                display: 'inline',
                cursor: 'pointer',
              }}
              color="blue"
              onClick={(e) => {
                if (rule && rule.id) {
                  e.stopPropagation(); // Prevent triggering the parent click
                  window.open(`/rule-studio/${rule.id}`, '_blank'); // Open in new tab
                }
              }}
            />
          </Text>
        </Group>
      ),
    },
    {
      accessor: 'is_active',
      title: 'Status',
      render: (rule) => (
        <Badge color={rule.is_active ? 'green' : 'gray'}>
          {rule.is_active ? 'Active' : 'In-Active'}
        </Badge>
      ),
    },
    {
      accessor: 'automations',
      title: 'Automations',
      render: () => (
        <Text size="sm" fw={500} c="blue">
          0
        </Text>
      ),
    },
    {
      accessor: 'updated_by',
      title: 'Updated By',
      render: (rule) => rule?.updated_by?.first_name,
    },
    {
      accessor: 'updated_at',
      title: 'Updated At',
      render: (rule) => transformDateString(rule?.updated_at),
    },
    {
      accessor: 'created_at',
      title: 'Created At',
      render: (rule) => transformDateString(rule?.created_at || ''),
    },
    {
      accessor: 'actions',
      title: 'Actions',
      render: () => (
        <Menu>
          <Menu.Target>
            <IconDotsVertical style={{ cursor: 'pointer' }} />
          </Menu.Target>
          {/* <Menu.Dropdown>
            <Menu.Item onClick={() => openActivityLog(rule.id)}>
              Activity Log
            </Menu.Item>
          </Menu.Dropdown> */}
        </Menu>
      ),
    },
  ];

  return (
    <Flex direction="column" w="100%" h="calc(100vh - 130px)">
      <TableWithFilters
        loading={loadingRules}
        records={rules}
        columns={columns}
        totalRecords={totalRules}
        page={page}
        onPageChange={(currentPage: number) => {
          onPageChange(currentPage);
        }}
        disableDataTable={disableDataTable}
        showSearch
        onSearch={onSearch}
        showFilterIcon
        onToggleFilters={handleDisableDataTable.toggle}
        onApplyFilters={onApplyFilters}
        toolBarConfig={{
          filtersConfig: RuleFilters,
        }}
        topActions={topActions}
      />
    </Flex>
  );
};

export default ListRules;
