import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthService } from 'Api/authService';
import { defineAbilitiesFor } from 'Src/abilities';
import { LoginCredentials, PermissionType } from 'Types/authTypes';
import { UserType } from 'Types/userTypes';
import { getUserInfo } from 'Utils/transform';

type Tenant = {
  id: number;
  name: string;
  is_active: boolean;
  slug: string;
};

export type User = {
  email: string;
  username: string;
  tenants?: Tenant[];
  expiry: string;
  token: string;
  currentTenant?: number;
  permissions?: PermissionType[];
  abilities?: any;
} & Pick<UserType, 'first_name' | 'last_name' | 'id'>;

export type AuthState = {
  isAuthenticated: boolean;
  user: User | null;
  isLoading?: boolean;
  error?: string | null;
  permissionsLoading?: boolean;
  permissionsError?: string | null;
};

export type AuthPayload = {
  expiry: string;
  token: string;
  email?: string;
  tenant?: number | undefined;
};

const initialState: AuthState = {
  isAuthenticated: !!getUserInfo()?.token,
  user: {
    ...getUserInfo(),
    // NOTE: revert when actually have RBAC
    // abilities: defineAbilitiesFor([{ codename: 'guest' }]),
  },
};

export const loginAsync = createAsyncThunk(
  'auth/login',
  async (data: LoginCredentials) => {
    try {
      const response = await AuthService.login(data);
      if (response && response.data) {
        const { user, token, expiry, tenants } = response.data;
        const tenantId = tenants.length > 0 ? tenants[0].id : undefined;
        const userDetails = {
          ...user,
          expiry,
          token,
          tenants,
          currentTenant: tenantId,
        };
        console.log('userDetails', userDetails);
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(userDetails));

        // Don't store permissions/abilities in localStorage
        // the permission BE is functioning incorrectly
        // created an isseue for it REV-107

        // const { data: permissionsData } =
        //   await AuthService.getUserPermissions();
        // if (!data) throw new Error('Permissions not found');

        // const permissions = permissionsData.results;
        // const abilities = defineAbilitiesFor(permissions);

        return {
          ...userDetails,
          // permissions,
          // abilities,
          user,
        };
      }
      throw new Error('Login failed');
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{
        user: User;
        token: string;
        expiry: string;
        tenants: Tenant[];
        permissions: PermissionType[];
      }>
    ) => {
      const { user, token, expiry, tenants, permissions = [] } = action.payload;
      if (user) {
        state.isAuthenticated = true;
        let defaultTenantId = undefined;
        if (tenants.length > 0) {
          // By default we set the first tenant as the default tenant
          // This might require some changes in future
          defaultTenantId = tenants[0].id;
        }
        state.user = {
          ...user,
          tenants,
          expiry,
          token,
          currentTenant: defaultTenantId,
          permissions,
          abilities:
            permissions.length > 0 ? defineAbilitiesFor(permissions) : null,
        };
        localStorage.setItem('user', JSON.stringify(state.user));
      }
      state.error = null;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem('user');
    },
    addTenant: (state, action: PayloadAction<{ tenantId: number }>) => {
      const { tenantId } = action.payload;
      if (tenantId && state.user) {
        state.user = { ...state.user, currentTenant: tenantId };
      }
      localStorage.setItem(
        'user',
        JSON.stringify({ ...state.user, currentTenant: tenantId })
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        authSlice.caseReducers.login(state, {
          payload: {
            user: action.payload.user,
            token: action.payload.token,
            expiry: action.payload.expiry,
            tenants: action.payload.tenants,
            permissions: action.payload.permissions,
          },
          type: 'auth/login',
        });
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { logout, addTenant, login } = authSlice.actions;
export default authSlice.reducer;
