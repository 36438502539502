import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion, Box, Flex, Loader, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CommentThreadsService } from 'Api/commentThreadsService';
import LinguisticService from 'Api/linguisticService';
import { LinguisticErrorHighlightShade } from 'Constants/index';
import { setHighlightedCoordinates } from 'Slices/activeDocumentSlice';
import { RootState } from 'Src/redux/store';
import { LinguisticErrorType } from 'Types/linguisticTypes';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'Utils/notifications';

import AssignUserModal from './AssignUserModal';
import GrammaticalSection from './GrammaticalSection';
import SpellCheckSection from './SpellCheckSection';
import { linguisticErrorsAtom } from './spellingGrammarAtom';

const GrammaticalTab = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [openAssignUserModal, handleOpenAssignUserModal] = useDisclosure(false);
  const [linguisticErrors, setLinguisticErrors] = useAtom(linguisticErrorsAtom);

  const [errorToAssign, setErrorToAssign] = useState<
    | {
        errorId: number;
        snapshotId: number;
      }
    | undefined
  >(undefined);
  const documentData = useSelector(
    (state: RootState) => state.activeDocument.data
  );
  const timeoutForFetching = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();

  // console.log(spellCheckErrors, 'spellCheckErrors');
  // console.log(grammaticalErrors, 'grammaticalErrors');

  useEffect(() => {
    fetchGrammaticalSpellData();
    return () => {
      if (timeoutForFetching.current) {
        clearTimeout(timeoutForFetching.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const errorsToBeHighlighted = linguisticErrors.map((error) =>
      error.word_blocks.map((block) => {
        return {
          ...block.bounding_box,
          page: block.page_number,
          highlight_color: LinguisticErrorHighlightShade[error.error_type],
        };
      })
    );
    console.log(errorsToBeHighlighted.flat(), 'errorsToBeHighlighted');
    if (errorsToBeHighlighted.length > 0)
      dispatch(setHighlightedCoordinates(errorsToBeHighlighted.flat()));
  }, [linguisticErrors, dispatch]);

  const fetchGrammaticalSpellData = async () => {
    try {
      console.log('fetching linguistic errors');
      if (documentData?.favorite_snapshot.id) {
        const { data } = await LinguisticService.getLinguisticErrors(
          documentData?.favorite_snapshot.id
        );
        const errorsWithComments = data.results.filter(
          (error: LinguisticErrorType) => error.comment_thread_id
        );

        const commentPromises = errorsWithComments.map(
          (
            error: Omit<LinguisticErrorType, 'comment_thread_id'> & {
              comment_thread_id: number;
            }
          ) =>
            CommentThreadsService.getCommentThreadsById(
              error.comment_thread_id
            ).then(({ data: commentData }) => ({
              ...error,
              comments: commentData,
            }))
        );
        const updatedErrors = await Promise.all(commentPromises);
        // Create a map of updated errors for quick lookup
        const updatedErrorsMap = new Map(
          updatedErrors.map((error) => [error.id, error])
        );

        // Merge updated errors with the existing errors list
        const mergedErrors = data.results.map(
          (error: any) => updatedErrorsMap.get(error.id) || error
        );
        console.log(mergedErrors, 'updatedErrors');
        setLinguisticErrors(mergedErrors);

        // this is a hacky solution to keep fetching the data every 60 seconds
        // we expect that there will be atleast one data point in the mergedErrors
        if (mergedErrors.length === 0) {
          timeoutForFetching.current = setTimeout(
            fetchGrammaticalSpellData,
            60000
          ); // 1 minute interval
        }
      } else {
        throw new Error('No snapshot found');
      }
    } catch (error: any) {
      if (timeoutForFetching.current) {
        clearTimeout(timeoutForFetching.current);
      }
      if (error instanceof Error) {
        showErrorNotification(
          error.message || 'Error fetching linguistic errors'
        );
      } else {
        showErrorNotification('Error fetching linguistic errors');
      }
    }
  };

  const handleErrorAssignment = (snapshotId: number, errorId: number) => {
    setErrorToAssign({ errorId, snapshotId });
    handleOpenAssignUserModal.open();
  };

  const ignoreErrors = async (
    errors: Array<{ snapshotId: number; errorId: number }>
  ) => {
    try {
      // Process all ignore requests in parallel
      await Promise.all(
        errors.map(({ errorId, snapshotId }) =>
          LinguisticService.markAsIgnored(errorId, snapshotId)
        )
      );

      // Update all errors in state at once
      const updatedErrors = linguisticErrors.map((error) =>
        errors.some((e) => e.errorId === error.id)
          ? { ...error, is_ignored: true }
          : error
      );

      setLinguisticErrors(updatedErrors);
      showSuccessNotification('Errors ignored successfully');
    } catch (error: any) {
      if (error instanceof Error) {
        showErrorNotification(error.message || 'Error ignoring errors');
      } else {
        showErrorNotification('Error ignoring errors');
      }
    }
  };

  return (
    <Box
      style={{
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll',
        width: '100%',
      }}
    >
      <Flex px="md" py="xs" pt="md">
        <Text fw={500} fz={18}>
          Spelling & Grammar
        </Text>
      </Flex>
      {linguisticErrors.length !== 0 ? (
        <Accordion value={activeSection} onChange={setActiveSection}>
          <GrammaticalSection
            handleErrorAssignment={handleErrorAssignment}
            ignoreErrors={ignoreErrors}
          />
          <SpellCheckSection
            handleErrorAssignment={handleErrorAssignment}
            ignoreErrors={ignoreErrors}
          />
        </Accordion>
      ) : (
        <Flex direction="column" justify="center" align="center" h="100%">
          <Loader mb="xl" size={30} />
          <Text size="sm" c="dimmed">
            Loading and analyzing grammatical and spell check errors. <br />
            This may take a moment on initial load......
          </Text>
        </Flex>
      )}
      <AssignUserModal
        open={openAssignUserModal}
        handleModal={handleOpenAssignUserModal}
        errorToAssign={errorToAssign}
      />
    </Box>
  );
};

export default GrammaticalTab;
