import React from 'react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AppShell as MantineAppShell } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import AuthRoute from 'Components/auth-route/AuthRoute';
import NavBar from 'Components/nav-bar/Navbar';
import SideBar from 'Components/side-bar/SideBar';

import classes from './DefaultLayout.module.scss';

const Dashboard: React.FC = () => {
  const [desktopOpened, handleDesktopOpened] = useDisclosure(true);
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    // Check if the current window is inside an iframe
    setIsIframe(window !== window.parent);
  }, []);

  // If it's in an iframe, only render the content without the shell
  if (isIframe) {
    return <>{<Outlet />}</>;
  }

  return (
    <AuthRoute>
      <MantineAppShell
        header={{ height: 60 }}
        navbar={{
          width: 220,
          breakpoint: 'sm',
          collapsed: { desktop: !desktopOpened },
        }}
        padding="md"
      >
        <NavBar
          handleDesktopOpened={handleDesktopOpened}
          desktopOpened={desktopOpened}
        />
        <SideBar />
        <MantineAppShell.Main className={classes.mainBody}>
          <Outlet />
        </MantineAppShell.Main>
      </MantineAppShell>
    </AuthRoute>
  );
};

export default Dashboard;
