import Messages from 'Messages';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  TextInput,
  PasswordInput,
  Anchor,
  Text,
  Divider,
  Grid,
  Stack,
  Title,
  Checkbox,
  Group,
  Box,
  Flex,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  IconBrandGithub,
  IconBrandGoogle,
  IconLock,
  IconLogin2,
  IconMail,
} from '@tabler/icons-react';
import Button from 'Components/button/Button';
import { loginAsync, addTenant } from 'Slices/authSlice';
import NaehasWhiteLogo from 'Src/assets/naehas-white-logo.png';
import ZenafideWhiteLogo from 'Src/assets/zenafide-logo-white.svg';
import { AppDispatch } from 'Src/redux/store';
import { useTheme } from 'Src/theme';
import { TenantType } from 'Src/types/userTypes';
import { LoginCredentials } from 'Types/authTypes';

import classes from './Login.module.scss';
import Tenant from './Tenant';

const {
  auth: { ATLEAST_ONE_TENANT },
} = Messages;

export function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState<'login' | 'tenants'>('login');
  const [tenants, setTenants] = useState<TenantType[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<number>();
  const { setThemeByEmail, brand } = useTheme();

  const handleLogin = async () => {
    const data: LoginCredentials = { email, password };
    try {
      const resultAction = await dispatch(loginAsync(data));
      const userInfo = unwrapResult(resultAction);
      setThemeByEmail(userInfo?.email);
      setTenants(userInfo.tenants);
      if (userInfo && userInfo.tenants.length > 1) {
        return setMode('tenants');
      } else if (userInfo && userInfo.tenants.length === 1) {
        const tenantId = userInfo.tenants[0].id;
        handleProceed(tenantId);
      } else {
        notifications.show({
          color: 'yellow',
          title: ATLEAST_ONE_TENANT.title,
          message: ATLEAST_ONE_TENANT.message,
          style: { width: 400, margin: 'auto' },
        });
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleProceed = (selectedTenantId: number) => {
    if (selectedTenantId) {
      dispatch(addTenant({ tenantId: selectedTenantId }));
      return navigate('/');
    }
  };

  return (
    <div>
      <Grid gutter={0}>
        <Grid.Col
          mih="100vh"
          span={8}
          className={
            brand === 'zenafide' ? classes.gradientBlue : classes.naehasGradient
          }
        >
          <Stack
            className={classes.logoContainer}
            gap="xs"
            align="flex-start"
            justify="left"
          >
            <img
              height={38}
              src={brand === 'zenafide' ? ZenafideWhiteLogo : NaehasWhiteLogo}
              alt="Zenafide Logo"
              className={classes.logo}
            />
            <Text size="lg" c="white">
              Your ally for quality control.
            </Text>
          </Stack>
          <div
            className={classes.shapeCircle}
            style={{ bottom: '-30%', left: '-10%' }}
          ></div>
          <div
            style={{ bottom: '-20%', left: '-20%' }}
            className={classes.shapeCircle}
          ></div>
        </Grid.Col>
        <Grid.Col span={4} style={{ display: 'flex' }}>
          <Stack justify="flex-end" gap="lg">
            <Flex justify="center" align="center" style={{ flex: 1 }}>
              {mode === 'login' ? (
                <Box miw={380}>
                  <Title order={2}>Hello Again!</Title>
                  <Title fw={200} order={3}>
                    Welcome back 👋
                  </Title>
                  <form style={{ marginTop: 40 }}>
                    <TextInput
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address"
                      leftSection={<IconMail />}
                      required
                      size="sm"
                    />
                    <PasswordInput
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      placeholder="Your password"
                      leftSection={<IconLock />}
                      required
                      size="sm"
                      mt="md"
                    />
                  </form>
                  <Group justify="space-between" mt="lg">
                    <Checkbox labelPosition="right" label="Remember me" />
                    <Anchor component="button" size="sm">
                      Forgot password?
                    </Anchor>
                  </Group>
                  <Button
                    id="login-button"
                    onClick={handleLogin}
                    fullWidth
                    mt="xl"
                    size="sm"
                    leftSection={<IconLogin2 />}
                  >
                    Login
                  </Button>
                  <Divider my="md" label="OR" labelPosition="center" />
                  <Stack>
                    <Button
                      leftSection={<IconBrandGithub />}
                      onClick={handleLogin}
                      variant="outline"
                      fullWidth
                      disabled
                      size="sm"
                    >
                      Login with Github
                    </Button>
                    <Button
                      leftSection={<IconBrandGoogle />}
                      onClick={handleLogin}
                      variant="outline"
                      fullWidth
                      disabled
                      size="sm"
                    >
                      Login with Google
                    </Button>
                  </Stack>
                </Box>
              ) : (
                <>
                  <Text fz="lg" fw={500} ta="center">
                    Select Tenant
                  </Text>
                  <Divider my="sm" />
                  {tenants.map(({ name, id }) => (
                    <Tenant
                      key={id}
                      id={id}
                      name={name}
                      setIsSelected={setSelectedTenant}
                      isSelected={selectedTenant === id}
                    />
                  ))}
                  <Button
                    onClick={() => {
                      if (selectedTenant !== undefined) {
                        handleProceed(selectedTenant);
                      }
                    }}
                    fullWidth
                    mt="xl"
                  >
                    Proceed
                  </Button>
                </>
              )}
            </Flex>
            <Box className={classes.footer}>
              <Text size="sm">
                By creating and account , you agree to the{' '}
                <Anchor href="#" target="_blank">
                  Terms and Services{' '}
                </Anchor>
                and{' '}
                <Anchor href="#" target="_blank">
                  Privacy Policy
                </Anchor>
              </Text>
            </Box>
          </Stack>
        </Grid.Col>
      </Grid>
    </div>
  );
}
