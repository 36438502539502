import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Flex, Grid, Paper, SimpleGrid, Title } from '@mantine/core';
import RuleService from 'Api/ruleService';
import useLoading from 'Src/hooks/useLoading';
import { FiltersType } from 'Types/commonTypes';
import { showErrorNotification } from 'Utils/notifications';
import debounce from 'lodash/debounce';

import ListRules from '../rules/components/ListRules';

export default function ListRules2() {
  const [rules, setRules] = useState([]);
  // const [openAddRuleDrawer, setOpenAddRuleDrawer] = useState(false);
  // const [ruleToView, setRuleToView] = useState<RuleType | null>(null);
  const [loadingRules, handleLoading] = useLoading(true);
  const [totalRules, setTotalRules] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [currentAppliedFilters, setCurrentAppliedFilters] =
    useState<FiltersType>({});
  // const [openAuditLogs, setOpenAuditLogs] = useState(false);
  // const [currentRuleId, setCurrentRuleId] = useState<number | null>(null);
  const navigate = useNavigate();

  const fetchRules = async (
    options: { page?: number; filters?: FiltersType } = {}
  ) => {
    const { page = 1, filters = currentAppliedFilters } = options;
    handleLoading.start();
    try {
      const response = await RuleService.getRules({
        filters: {
          page,
          ...filters,
        },
      });
      if (response && response.data) {
        const { results = [], count } = response.data;
        setRules(results);
        setTotalRules(count);
      }
    } catch (err: any) {
      showErrorNotification(err.message ?? 'Error while fetching rules');
    } finally {
      handleLoading.stop();
    }
  };

  const searchRule = debounce(async (text: string) => {
    setPage(1);
    if (!text || text.length == 0) {
      return fetchRules();
    }
    handleLoading.start();
    try {
      const response = await RuleService.searchRule(text);
      setRules(response.data);
    } catch (e) {
      showErrorNotification('Something went wrong while performing search');
    } finally {
      handleLoading.stop();
    }
  }, 300);

  // const openFilledDrawer = (id: number) => {
  //   const ruleToView = rules.find((rule: RuleType) => rule.id === id);
  //   if (ruleToView) {
  //     setRuleToView(ruleToView);
  //     setOpenAddRuleDrawer(true);
  //   } else {
  //     notifications.show({
  //       title: 'Error',
  //       message: 'Rule not found',
  //       color: 'red',
  //     });
  //   }
  // };

  useEffect(() => {
    fetchRules();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // const handleNewRule = () => {
  //   if (ruleToView) {
  //     setRuleToView(null);
  //   }
  //   setOpenAddRuleDrawer(true);
  // };

  const onPageChange = (page: number) => {
    setPage(page);
    fetchRules({ page });
  };

  const onApplyFilters = (filters: FiltersType) => {
    const filtersEmpty = Object.keys(filters).length === 0;
    setCurrentAppliedFilters(filters);
    if (filtersEmpty) setPage(1);
    fetchRules({ filters });
  };

  // useEffect(() => {
  //   if (currentRuleId) {
  //     setOpenAuditLogs(true);
  //   } else {
  //     setOpenAuditLogs(false);
  //   }
  // }, [currentRuleId]);

  return (
    <SimpleGrid cols={1}>
      <Flex justify="space-between">
        <Title order={4}>Rules Studio</Title>
      </Flex>
      <Paper radius={5} p="xs">
        <Grid pos="relative" w="100%" p="0">
          <Grid.Col span={12} w="100%" p="0">
            <ListRules
              rules={rules}
              onPageChange={onPageChange}
              loadingRules={loadingRules}
              totalRules={totalRules}
              page={page}
              onSearch={searchRule}
              onApplyFilters={onApplyFilters}
              topActions={
                <Button
                  onClick={() => navigate('/rule-studio/new')}
                  variant="outline"
                  radius="md"
                  size="xs"
                >
                  Create New
                </Button>
              }
            />
          </Grid.Col>
        </Grid>
      </Paper>
      {/* <AddRuleModal
        setRuleToView={setRuleToView}
        openAddRuleDrawer={openAddRuleDrawer}
        setOpenAddRuleDrawer={setOpenAddRuleDrawer}
        ruleToView={ruleToView}
        refreshRules={fetchRules}
      />
      <AuditLogs
        open={openAuditLogs}
        onClose={() => {
          setOpenAuditLogs(false);
          setCurrentRuleId(null);
        }}
        contentType={'rules.rule'}
        entities={{
          list: rules,
          key: 'title',
          currentEntity: currentRuleId ?? null,
          contentType: 'rules.rule',
          name: 'Rule',
        }}
      /> */}
    </SimpleGrid>
  );
}
